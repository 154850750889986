import CloseIcon from 'components/icons/CloseIcon';

import './modal.style.scss';

const Modal = ({ children, onClose }) => (
  <div className="modal">
    <div className="close" onClick={onClose}>
      <CloseIcon width={50} height={50} color="#03A9F4" fill="#03A9F4" />
    </div>
    {children}
  </div>
)

export default Modal;