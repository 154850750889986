import Bar from "components/generics/Charts/Bar";

const DelaysTypeContainer = ({data, loading}) => (
  !loading ? (
    <>
      <div className="graph_container" style={{marginTop: 20}}>
        <Bar
          data={data.delays}
          keys={['counter']}
          indexBy={'delayTypeName'}
        />
      </div>
    </>
  ) : (
    <>
      loading
    </>
  )
);

export default DelaysTypeContainer;