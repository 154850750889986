import { useState, useEffect } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import FormAircraftType from 'components/pages/parameters/AircraftTypes/FormAircraftType';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import useAuth from 'providers/Auth/useAuth';


import {
  get as getAircraftType,
  bulkDelete as deleteAircraftType
} from 'services/API/AircraftType';

import { prepareTable } from 'utils/func';
import { AIRCRAFTTYPE_HEADERS } from 'utils/const'

const AircraftTypes = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormtype] = useState(null);
  const [selectedAircraftTypes, setSelectedAircraftTypes] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, [])

  const getDatas = () => {
    getAircraftType()
      .then(res => {
        setData({ datas: prepareTable(AIRCRAFTTYPE_HEADERS, res.data), headers: AIRCRAFTTYPE_HEADERS })
      })
      .catch(e => console.error(e));
  }

  const openModalEdit = (e) => {
    setFormtype('edit');
    setEditId(e.id);
    setModalVisibility(true);
  }

  const openModalAdd = (e) => {
    setFormtype('add');
    setEditId(null);
    setModalVisibility(true);
  }

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  }

  const onDelete = () => {
    if (selectedAircraftTypes.length > 0) {
      deleteAircraftType({ids: selectedAircraftTypes.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  }

  return (
    <div className='params crud' id="airports">
      <div className="crud__header">
        <div>
          <h1>Aircraft types</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <Button
          text='Delete'
          disabled={selectedAircraftTypes.length === 0}
          defaultStyle={`primary${selectedAircraftTypes.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
        {/* <Button
          text='Export'
          disabled={selectedAircraftTypes.length === 0}
          defaultStyle={`primary${selectedAircraftTypes.length === 0 ? ' disabled' : ''}`}
          onClick={() => console.log('Export')}
        /> */}
        <Button
          text='Add Aircraft type'
          defaultStyle='primary'
          onClick={openModalAdd}
        />
      </div>
      <Table
        selectedRows={d => setSelectedAircraftTypes(d)}
        datas={data.datas}
        headers={data.headers}
        editable={true}
        onEdit={openModalEdit}
        name='aircraftTypesFilters'
      />
      { modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormAircraftType onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      ) }
      { confirmDeletePopup && (
        <ConfirmDelete 
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedAircraftTypes}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
}

export default AircraftTypes;