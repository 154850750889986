import API from 'services/API';

export const login = (creds) => API.post('/auth/signin', creds);

export const forgotPassword = (data) => API.post('/auth/forgotten_password', data)

export const get = () => API.get('/user');

export const getById = (id) => API.get(`/user/${id}`);

export const add = (user) => API.post('/user', user)

export const bulkDelete = (users) => API.delete('/user/byBulk', { data: users });

export const edit = (user) => API.patch(`/user/${user.id}`, user);

export const requestPassword = (email) => API.post(`/user/resetPassword/stepToken`, email);

export const resetPassword = (data) => API.post(`/user/resetPassword/stepChange`, data);

export const updateDatasource = (data) => API.post(`/user/changeDatasource`, data);


