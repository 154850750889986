import API from 'services/API';

export const getDatabases = () => API.get(`/database-manager`);

export const getSelectedDatabase = () => API.get(`/database-manager/selected`);

export const switchDatabase = (year) => API.patch(`/database-manager/selected`, {year});

export const createDatabase = (formData) => API.post(`/database-manager`, formData);

export const deleteDatabase = (name) => API.delete(`/database-manager/${name}`)