/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";

import './Flights.style.scss';

import Table from 'components/generics/Table/ReactTable';
import Modal from 'components/generics/Modal/Modal';
import FlightForm from 'components/pages/Flights/FlightForm';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import FlightButton from 'components/pages/Flights/FlightButton';

import useAuth from 'providers/Auth/useAuth';
import { prepareTable, getFlightHeaderByRole } from 'utils/func';

import { 
  get as getFlights,
  bulkDelete as deleteFlights,
  bulkCancel as cancelFlights
} from 'services/API/Flight';

const Flights = () => {
  const { anomalies } = useParams();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormType] = useState(null);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [anomaliesIds] = useState(anomalies.split(',').map(Number))
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getFlights()
      .then(res => {
        setData({
          datas: prepareTable(
            getFlightHeaderByRole(
              user.roleName),
              anomaliesIds.map(d => res.data.find(v => v.id === d))
                .sort((a, b) => (a.takeoffTimeUtc - b.takeoffTimeUtc))
                .sort((a, b) => +new Date(a.date) - +new Date(b.date))
          ),
          headers: getFlightHeaderByRole(user.roleName)
        })
      })
      .catch(e => toast.error(e.message));
  }

  useEffect(() => {
    if (selectedFlights.length === 1) {
      setEditId(selectedFlights[0].original.id);
    } else {
      setEditId(null);
    }
  }, [selectedFlights])

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  }

  const onDelete = () => {
    if (selectedFlights.length > 0) {
      deleteFlights({ids: selectedFlights.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);          
        })
        .catch(err => console.error(err));
    }
  }

  const onCancel = () => {
    if(selectedFlights.length > 0) {
      cancelFlights({ids: selectedFlights.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);          
        })
        .catch(err => console.error(err));
    }
  }

  return (
    <div className='flights' id="flights-all">
      <div className="crud__header">
        <div>
          <h1>Flights anomalies</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <FlightButton
          allowedRoles={['Admin', 'CPSAdmin', 'CPSUser']}
          text='Edit'
          disabled={selectedFlights.length !== 1}
          defaultStyle='primary'
          onClick={() => {
            setFormType('edit');
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={['Admin', 'CPSAdmin', 'CPSUser']}
          text='Cancel'
          disabled={selectedFlights.length === 0}
          defaultStyle='primary'
          onClick={onCancel}
        />
        <FlightButton
          allowedRoles={['Admin', 'CPSAdmin', 'CPSUser']}
          text='Delete'
          disabled={selectedFlights.length === 0}
          defaultStyle='primary'
          onClick={() => setConfirmDeletePopup(true)}
        />
      </div>
      <Table
        selectedRows={d => setSelectedFlights(d)}
        datas={data.datas}
        headers={data.headers}
        name="flightsAnomaliesFilters"
      />
      { modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FlightForm onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>) }
      { confirmDeletePopup && (
        <ConfirmDelete 
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedFlights}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
}

export default Flights;