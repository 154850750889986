import { v1 } from 'uuid';

import './quickFlightsTable.style.scss';

import QuickFlightsHeader from 'components/dashboard/QuickFlightsTable/QuickFlightsTableHead';
import QuickFlightsRow from 'components/dashboard/QuickFlightsTable/QuickFlightsTableRow';

const QuickFlightsTable = ({ datas }) => (
  <table className='QuickFlightTable'>
    <QuickFlightsHeader />
    { datas.length ? (
      <tbody>
        {datas?.map(row => (
          <QuickFlightsRow key={v1()} data={row} />
        ))}
      </tbody> ) : (
        <tbody>
          <tr>
            <td colSpan={6} className='nodata'>NO DATA</td>
          </tr>
        </tbody>
      )
    }
  </table>
);

export default QuickFlightsTable;