import React from "react";
import PropTypes from 'prop-types';

const CalendarIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 43 43"
  >
    <g fill={fill} color={color} transform="translate(5 5)">
      <path d="M31.246 28.481a1.27 1.27 0 001.27-1.27V7.621a5.087 5.087 0 00-5.08-5.081h-1.651V1.27a1.27 1.27 0 00-2.541 0v1.27h-5.779V1.27a1.27 1.27 0 00-2.541 0v1.27H9.208V1.27a1.27 1.27 0 00-2.541 0v1.27H5.08A5.087 5.087 0 000 7.621v19.818a5.086 5.086 0 005.08 5.08h22.351a5.086 5.086 0 005.08-5.08 1.27 1.27 0 10-2.54 0 2.543 2.543 0 01-2.541 2.54H5.075a2.543 2.543 0 01-2.541-2.54V7.621a2.543 2.543 0 012.541-2.54h1.587v1.27a1.27 1.27 0 002.541 0v-1.27h5.716v1.27a1.27 1.27 0 002.541 0v-1.27h5.779v1.27a1.27 1.27 0 002.541 0v-1.27h1.651a2.544 2.544 0 012.541 2.54v19.59a1.27 1.27 0 001.274 1.27z" />
      <path d="M9.209 13.336a1.27 1.27 0 00-2.541 0 1.27 1.27 0 002.541 0z" />
      <path d="M20.259 13.336a1.27 1.27 0 10-2.54 0 1.27 1.27 0 102.54 0z" />
      <path d="M25.785 13.336a1.27 1.27 0 00-2.541 0 1.27 1.27 0 002.541 0z" />
      <path d="M9.209 18.862a1.27 1.27 0 00-2.541 0 1.27 1.27 0 002.541 0z" />
      <path d="M7.938 23.117a1.27 1.27 0 00-1.27 1.27 1.27 1.27 0 002.541 0 1.27 1.27 0 00-1.271-1.27z" />
      <path d="M14.733 13.336a1.27 1.27 0 10-2.54 0 1.27 1.27 0 102.54 0z" />
      <path d="M14.733 18.862a1.27 1.27 0 10-2.54 0 1.27 1.27 0 102.54 0z" />
      <path d="M13.463 23.117a1.27 1.27 0 00-1.27 1.27 1.27 1.27 0 002.54 0 1.27 1.27 0 00-1.27-1.27z" />
      <path d="M20.259 18.862a1.27 1.27 0 10-2.54 0 1.27 1.27 0 102.54 0z" />
      <path d="M24.514 17.592a1.27 1.27 0 00-1.27 1.27 1.27 1.27 0 002.541 0 1.27 1.27 0 00-1.271-1.27z" />
      <path d="M18.989 23.117a1.27 1.27 0 00-1.27 1.27 1.27 1.27 0 002.54 0 1.27 1.27 0 00-1.27-1.27z" />
    </g>
  </svg>
);

CalendarIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CalendarIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 32.516,
  height: 32.516,
};

export default CalendarIcon;
