/* eslint-disable import/no-anonymous-default-export */
import API from 'services/API';

export const get = () => API.get('/flight');

export const getScheduled = () => API.get('/flight/findByStatus?statusId=2');

export const getCancelled = () => API.get('/flight/findByStatus?statusId=3');

export const add = (flight) => API.post('/flight', flight);

export const edit = (flight, id) => API.patch(`/flight/${id}`, flight);

export const getById = (id) => API.get(`/flight/${id}`);

export const getByDate = (start, end) => API.get(`/flight/findByDate?startDate=${start}&endDate=${end}`);

export const bulkDelete = (flights) => API.delete('/flight/byBulk', { data: flights });

export const bulkCancel = (flights) => API.patch('/flight/statusByBulk', { flightStatusId: 3, ...flights });

export const exportData = () => API.get('/export/csv/full/Flight');

export default { get, getById };
