import PropTypes from 'prop-types';

const TLBTimeIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40.989 49.968"
  >
    <g
      color={color}
      fill={fill}
      id="tlb-time"
      transform="translate(-1767.932 -113.56)"
    >
      <path
        id="Trac\xE9_171"
        data-name="Trac\xE9 171"
        d="M1786.475,128.316h.023a.976.976,0,0,0,0-1.952h-.023a.976.976,0,0,0,0,1.952Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_172"
        data-name="Trac\xE9 172"
        d="M1805.994,113.56h-27.326a2.931,2.931,0,0,0-2.928,2.928v6.051h-4.88a2.931,2.931,0,0,0-2.928,2.927V160.6a2.932,2.932,0,0,0,2.928,2.928h27.326a2.931,2.931,0,0,0,2.928-2.928v-6.051h4.88a2.93,2.93,0,0,0,2.927-2.928V116.488A2.93,2.93,0,0,0,1805.994,113.56Zm-6.832,47.04a.977.977,0,0,1-.976.976H1770.86a.977.977,0,0,1-.976-.976V125.466a.977.977,0,0,1,.976-.976h20.495v1.874H1789.3a.976.976,0,0,0,0,1.952h2.053v1.054a2.931,2.931,0,0,0,2.927,2.928h4.88v28.3Zm-5.855-34.729,4.475,4.475h-3.5a.976.976,0,0,1-.975-.976Zm13.663,25.75a.977.977,0,0,1-.976.976h-4.88V131.362c0-.014,0-.027,0-.04a.977.977,0,0,0-.323-.726l-7.772-7.772h0a.962.962,0,0,0-.1-.084l-.024-.018a.852.852,0,0,0-.108-.068l-.009,0a.992.992,0,0,0-.11-.048l-.024-.009a.889.889,0,0,0-.125-.034h-.006a.927.927,0,0,0-.118-.015l-.035,0h-14.671v-6.051a.977.977,0,0,1,.976-.976h27.326a.977.977,0,0,1,.976.976Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_173"
        data-name="Trac\xE9 173"
        d="M1791.185,137.324a2.8,2.8,0,0,0-2.068.886l-1.231,1.316-1.488,1.543-8.429-2.567a.975.975,0,0,0-.974.243l-1.8,1.8a.977.977,0,0,0,.186,1.526l6.37,3.844-1.562,1.661-2.229-.161a.978.978,0,0,0-.76.284l-1.514,1.514a.976.976,0,0,0,.263,1.567l3.181,1.551,1.551,3.181a.975.975,0,0,0,1.567.262l1.514-1.514a.973.973,0,0,0,.283-.76l-.16-2.229,1.661-1.562,3.844,6.37a.979.979,0,0,0,.718.465.922.922,0,0,0,.118.007.977.977,0,0,0,.69-.286l1.8-1.8a.973.973,0,0,0,.244-.973l-2.552-8.432,1.263-1.232,1.582-1.486a2.8,2.8,0,0,0,.884-2.021,2.945,2.945,0,0,0-2.951-3Zm.73,3.6-1.588,1.492-.014.013-1.694,1.653a.976.976,0,0,0-.252.981l2.554,8.441-.5.5-3.825-6.337a.975.975,0,0,0-.7-.463,1,1,0,0,0-.133-.009.98.98,0,0,0-.669.265l-2.879,2.707a.976.976,0,0,0-.3.781l.162,2.248-.231.231-1.1-2.254a.973.973,0,0,0-.449-.449l-2.253-1.1.231-.231,2.247.162a.977.977,0,0,0,.781-.3l2.707-2.879a.976.976,0,0,0-.206-1.5l-6.337-3.824.5-.5,8.439,2.571a.976.976,0,0,0,.987-.256l1.909-1.979.011-.011,1.235-1.322a.875.875,0,0,1,.643-.268,1,1,0,0,1,1,1.015A.868.868,0,0,1,1791.915,140.919Z"
        fill="#03a9f4"
      />
    </g>
  </svg>
);

TLBTimeIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

TLBTimeIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 40.989,
  height: 49.968,
};

export default TLBTimeIcon;
