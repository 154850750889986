import React from "react";
import PropTypes from 'prop-types';

const ToIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 47.999 42"
  >
    <g fill={fill} color={color}>
      <path d="M32.999 15.002a1 1 0 01-.911-.583L26.439 2.172l-2.358.59.914 9.14a1 1 0 01-1.99.2l-1-10a1 1 0 01.752-1.07l4-1a1 1 0 011.15.551l6 13a1 1 0 01-.49 1.328.967.967 0 01-.418.091z" />
      <path d="M38.999 22.001a1 1 0 010-2c3.081 0 4-.454 4.172-.56a1 1 0 011.782.858c-.352 1.134-2.36 1.702-5.954 1.702z" />
      <path d="M26.999 38.001a.955.955 0 01-.2-.019l-5-1a1 1 0 01-.8-1.1l2-17a1 1 0 01.995-.882c2.958 0 9.049 1.964 9.307 2.048a1 1 0 01.628 1.3l-6 16a1 1 0 01-.93.653zm-3.9-2.8l3.263.651 5.33-14.21a45.126 45.126 0 00-6.808-1.588z" />
      <path d="M43.419 27.627a62.845 62.845 0 01-11.484-1.642 1 1 0 11.407-1.958c7.929 1.644 12.5 2.038 13.563 1.169a.2.2 0 00.094-.194c0-4.87-7.862-8.372-15.219-10.024a161.382 161.382 0 00-17.883-2.98 1 1 0 01-.792-.55L8.463 4.166l-2.464.616v6.22a1 1 0 01-.424.816.981.981 0 01-.911.124 4.126 4.126 0 00-2.582-.234c-.022.016-.082.06-.082.294.024.248.658 1.272 3.276 2.958a1 1 0 01.406 1.158l-1.294 3.884h2.994l1.062-2.126a1 1 0 011.324-.456 65.53 65.53 0 0013.449 4.605 1 1 0 01-.438 1.95 66.978 66.978 0 01-12.981-4.336l-.9 1.809a1 1 0 01-.894.553h-5a1 1 0 01-.948-1.318l1.492-4.474C.004 13.783.004 12.453.004 12.001a2.224 2.224 0 01.932-1.928 4.178 4.178 0 013.068-.372v-5.7a1 1 0 01.758-.971l4-1a1 1 0 011.136.522l3.756 7.512a161.428 161.428 0 0117.584 2.961c3.922.882 16.766 4.361 16.766 11.974a2.191 2.191 0 01-.83 1.746 5.941 5.941 0 01-3.755.882z" />
      <path d="M22.999 42.001h-22a1 1 0 010-2h22a1 1 0 110 2z" />
    </g>
  </svg>
);

ToIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ToIcon.defaultProps = {
  color: '#000',
  fill: '#03a9f4',
  width: 47.999,
  height: 42,
};

export default ToIcon;
