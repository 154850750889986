import React from "react";
import PropTypes from 'prop-types';

const DeleteIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19.372 25.333"
    color={color}
    fill={fill}
  >
    <path data-name="Rectangle 137" d="M8.941 11.922h1.49v8.196h-1.49z" />
    <path data-name="Rectangle 138" d="M5.961 11.922h1.49v8.196h-1.49z" />
    <path data-name="Rectangle 139" d="M11.922 11.922h1.49v8.196h-1.49z" />
    <path
      data-name="Trac\xE9 54"
      d="M19.372 2.98h-5.961v-.745A2.238 2.238 0 0011.176 0H8.195A2.238 2.238 0 005.96 2.235v.745H-.001v4.471h1.552l1.37 15.758a2.23 2.23 0 002.233 2.124h9.063a2.23 2.23 0 002.232-2.124l1.37-15.758h1.552zM7.451 2.235a.746.746 0 01.745-.745h2.981a.746.746 0 01.745.745v.745H7.451zM1.49 4.47h16.392v1.49H1.49zm13.472 18.658a.743.743 0 01-.744.714H5.155a.743.743 0 01-.744-.714L3.048 7.45h13.277c0 .045.089-1.022-1.362 15.678zm0 0"
    />
  </svg>
)

DeleteIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DeleteIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 19.372,
  height: 25.333,
};

export default DeleteIcon;
