import React from "react";
import PropTypes from 'prop-types';

import './menuIcon.scss';

const MenuIcon = ({ active, fill, stroke, color, width, height }) => (
  <svg
    className='menuIcon'
    width={width}
    height={height}
    viewBox="0 0 200 200"
    color={color}
  >
    <g className={active ? 'active' : undefined} strokeWidth="6.5" strokeLinecap="round">
      <path
        d="M72 82.286h28.75"
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
      />
      <path
        d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
        fill="none"
        stroke={stroke}
      />
      <path
        d="M72 125.143h28.75"
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
      />
      <path
        d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
        fill="none"
        stroke={stroke}
      />
      <path
        d="M100.75 82.286h28.75"
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
      />
      <path
        d="M100.75 125.143h28.75"
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
      />
    </g>
  </svg>
)

MenuIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

MenuIcon.defaultProps = {
  active: false,
  color: '#000',
  stroke: '#000',
  width: 45,
  height: 45,
};

export default MenuIcon