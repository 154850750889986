import { useState, useEffect } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import FormCompanie from 'components/pages/parameters/Companies/FormCompanie';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import useAuth from 'providers/Auth/useAuth';

import {
  get as getCompanie,
  bulkDelete as deleteCompanie
} from 'services/API/Airline';

import { prepareTable } from 'utils/func';
import { COMPANIE_HEADERS } from 'utils/const'

const Companie = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormtype] = useState(null);
  const [selectedCompanie, setSelectedCompanie] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, [])

  const getDatas = () => {
    getCompanie()
      .then(res => {
        setData({ datas: prepareTable(COMPANIE_HEADERS, res.data), headers: COMPANIE_HEADERS })
      })
      .catch(e => console.error(e));
  }

  const openModalEdit = (e) => {
    setFormtype('edit');
    setEditId(e.id);
    setModalVisibility(true);
  }

  const openModalAdd = (e) => {
    setFormtype('add');
    setEditId(null);
    setModalVisibility(true);
  }

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  }

  const onDelete = () => {
    if (selectedCompanie.length > 0) {
      deleteCompanie({ids: selectedCompanie.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  }

  return (
    <div className='params crud' id="companie">
      <div className="crud__header">
        <div>
          <h1>Airlines</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <Button
          text='Delete'
          disabled={selectedCompanie.length === 0}
          defaultStyle={`primary${selectedCompanie.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
        {/* <Button
          text='Export'
          disabled={selectedCompanie.length === 0}
          defaultStyle={`primary${selectedCompanie.length === 0 ? ' disabled' : ''}`}
          onClick={() => console.log('Export')}
        /> */}
        <Button
          text='Add companie'
          defaultStyle='primary'
          onClick={openModalAdd}
        />
      </div>
      <Table
        selectedRows={d => setSelectedCompanie(d)}
        datas={data.datas}
        headers={data.headers}
        editable={true}
        onEdit={openModalEdit}
        name='companiesFilters'
      />
      { modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormCompanie onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      ) }
      { confirmDeletePopup && (
        <ConfirmDelete 
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedCompanie}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
}

export default Companie;