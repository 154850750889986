import { useState } from 'react';

import CloseIcon from 'components/icons/CloseIcon';
import Button from 'components/generics/Button/Button';

import './confirmDelete.style.scss';

const ConfirmDelete = ({ onClose, list, onConfirm, formatList }) => {
  const [showList, setShowList] = useState(false)

  return (
    <div className="confirmDelete">
      <div className="popup__content">
        <h2>DELETE</h2>
        <div className="close" onClick={onClose}>
          <CloseIcon width={50} height={50} color="#03A9F4" fill="#03A9F4" />
        </div>
        <p>{list.length} items selected</p>
        <p className="toggle_list" onClick={() => setShowList(!showList)}>View list</p>
        {showList && (
          <div className='list'>
            {list.map(l => <p>{formatList(l.original)}</p>)}
          </div>
        )}
        <h3>Are you sure ?</h3>
        <div className='actions'>
          <Button defaultStyle='primary' text='DELETE' onClick={onConfirm} />
          <p className='cancel' onClick={onClose}>Cancel</p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDelete;