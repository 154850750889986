import { toast } from 'react-toastify';

/**
 * UTILS FUNCTION FOR CPS PROJECT
 */

import {
  DAYS_MINUTES,
  FLIGHT_HEADERS_ADMIN,
  FLIGHT_HEADERS_ASO,
  FLIGHT_HEADERS_AUTHORITY,
  FLIGHT_HEADERS_AIRLINE,
  FLIGHT_HEADERS_AIRPORT,
  FLIGHT_HEADERS,
  FLIGHT_HEADERS_DISPATCH,
  FLIGHT_HEADERS_PASSENGERS,
  FLIGHT_HEADERS_CREW
} from 'utils/const';

/**
 * 
 * @param {Number} num int value
 * @returns return string value with 0 before if the num value < 10
 * 
 * @example addZero(9);   // 09
 * @example addZero(20);  // 20
 */
export const addZero = (num) => (num < 10 ? `0${num}` : num);

/**
 * 
 * @param {Number} value Nb minutes
 * @returns hour value as hh:mm format
 * 
 * @example formatMinutesToReadableHour(500); // 08:20
 * 
 */
export const formatMinutesToReadableHour = (value) => {
  if (value && value > 0) {
    return  `${addZero(Math.floor((value % DAYS_MINUTES) / 60 ))}:${addZero(Math.floor(value%60))}`;
  } else {
    return '--:--';
  }
}

/**
 * 
 * @param {String} value hour value as hh:mm format
 * @returns nb minutes from midnight
 * 
 * @example formatReadableHourToMinutes(2:50);  // 170
 * @example formatReadableHourToMinutes(02:50); // 170
 * @example formatReadableHourToMinutes(20:30); // 1230
 */
export const formatReadableHourToMinutes = (value) => {
  if (value) {
    const splited = value.split(':');
    return +splited[0] * 60 + +splited[1];
  }
  return '00:00';
}

/**
 *
 * @param {Object} values Old values
 * @param {Func} updateFunc Update function
 * @param {String} key Attribute to update
 * @param {Any} value Value to update
 * @returns
 */
 export const updateForm = (values, updateFunc, key, value) =>
 updateFunc({ ...values, [key]: value });

 /**
 * return last value of nested object in terms of keys array path
 * @param {Object} obj Object values
 * @param {Array} keys Array of keys
 * @param {Number} i Interation of keys deepness
 * @returns
 */
export const deepGet = (obj, keys, i) => 
i >= keys.length ? obj : deepGet(obj[keys[i]], keys, i + 1);

/**
 * 
 * @param {Array} headers Table headers
 * @param {Array} datas Table API datas
 * @returns This function formate datas to display in table
 */
export const prepareTable = (headers, datas) =>
datas.map((data) => {
  const ROW = {};
  headers.forEach((h) => {
    if (h.bindData) {
      const accessors = h.bindData.split(".");
      ROW[h.accessor] = h.format(deepGet(data, accessors, 0));
    } else {
      ROW[h.accessor] = h.format(data);
    }
  });
  return ROW;
});

/**
 * 
 * @param {Date} date Date to format
 * @returns formated date as : dd/mm/yyyy
 */
export const formatDate = (date) =>
  `${addZero(date.getDate())}/${addZero((date.getMonth() % 12) + 1)}/${date.getFullYear()}`;

/**
 * 
 * @param {Date} date Date to format
 * @returns formated date as : yyyy/mm/dd
 */
export const formatDateForInputs = (date) =>
  `${date.getFullYear()}-${addZero(date.getMonth() % 12 + 1)}-${addZero(date.getDate())}`;


/**
 * 
 * @param {Date} date Date to format
 * @returns formated date as : dd/mm/yyyy hh:mm
 */
export const formatDateAndHour = (date) => 
  `${formatDate(date)} ${addZero(date.getHours())}:${addZero(date.getMinutes())}`;

/**
 * 
 * @param {Array} allowedRoles Array of roles string
 * @param {String} userRole User role
 * @returns {Boolean} Return boolean if userRoles exist in allowedRoles Array
 */
export const isAuthorised = (allowedRoles, userRole) => (allowedRoles.findIndex((r) => (r === userRole)) !== -1)

export const getFlightHeaderByRole = (role) => {
  switch(role) {
    case 'Admin':
      return FLIGHT_HEADERS_ADMIN;
    case 'CPSAdmin':
      return FLIGHT_HEADERS_ADMIN;
    case 'CPSUser':
      return FLIGHT_HEADERS_ADMIN;
    case 'ASO':
      return FLIGHT_HEADERS_ASO;
    case 'Airline':
      return FLIGHT_HEADERS_AIRLINE;
    case 'Airport':
      return FLIGHT_HEADERS_AIRPORT;
    case 'Authority':
      return FLIGHT_HEADERS_AUTHORITY;
    case 'DISPATCH':
      return FLIGHT_HEADERS_DISPATCH;
    case 'PASSENGERS':
      return FLIGHT_HEADERS_PASSENGERS;
    case 'CREW':
      return FLIGHT_HEADERS_CREW;
    default:
      return FLIGHT_HEADERS
  }
}

/**
 * 
 * @param {Array || String} messages Array of messages or message
 * @param {Number} duration Duration of toast
 * Sometimes api return an array of messages, sometimes a string, this function manage this to display toast correctly
 */
export const toastErrors = (messages, duration) => {
  if (typeof messages === "object") {
    messages.forEach((m) => toast.error(m, { autoClose: duration }));
  } else {
    toast.error(messages, { autoClose: duration });
  }
}

// Save value into localstorage
export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// Get value from localstorage
export const getFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * 
 * @param {Date} date Date to format
 * @returns UTC value of a Date
 */
export const convertDateToUTC = (date) => { 
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ); 
}