import { NavLink } from "react-router-dom";

import './tab.scss';

const Tab = ({ title, path, url }) => (
  <NavLink className="tab" to={`${url}/${path}`}>
    {title}
  </NavLink>
);

export default Tab;