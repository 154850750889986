import API from 'services/API';

export const get = () => API.get('/airport');

export const getById = (id) => API.get(`/airport/${id}`);

export const add = (airport) => API.post('/airport', airport);

export const edit = (airport) => API.patch(`/airport/${airport.id}`, airport);

export const bulkDelete = (airport) => API.delete('/airport/byBulk', { data: airport });
