import React from 'react';

import { v1 } from 'uuid';

const QuickFlightsTableRow = ({ data }) => (
  <tr>
    {data?.map(d => <td key={v1()}>{d}</td>)}
  </tr>
)

export default QuickFlightsTableRow;