import React from "react";
import PropTypes from "prop-types";

const BoardingIcon = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 40.404 43.741">
    <g
      id="boarding"
      transform="matrix(0.809, 0.588, -0.588, 0.809, -904.243, -945.826)"
    >
      <path
        d="M1322.5,241.091a.9.9,0,0,0,1.371-.956.894.894,0,0,0-.4-.567l-4.978-3.188V232.85a.9.9,0,0,0-1.809,0v3.531l-4.98,3.188a.9.9,0,0,0,.975,1.523l4-2.564v4.889l-1.721,1.721a.9.9,0,0,0,1.279,1.278l1.346-1.345,1.346,1.345a.925.925,0,0,0,1.279,0,.906.906,0,0,0,0-1.279l-1.72-1.72v-4.889Z"
        transform="translate(-0.906 -1.732)"
        fill={fill}
        color={color}
      />
      <path
        d="M1327.074,220.676h-4.9a.906.906,0,0,0-.9.9,4.582,4.582,0,0,1-9.163,0,.906.906,0,0,0-.9-.9h-4.9a.906.906,0,0,0-.9.9v35.853a.906.906,0,0,0,.9.9h20.77a.906.906,0,0,0,.9-.9V221.581A.906.906,0,0,0,1327.074,220.676Zm-.9,35.854h-18.961V222.485h3.152l.03.176a6.391,6.391,0,0,0,12.6,0l.03-.176h3.152Z"
        fill={fill}
        color={color}
      />
      <path
        d="M1324.9,252.493h-3.69a.9.9,0,0,0,0,1.809h3.69a.9.9,0,1,0,0-1.809Z"
        transform="translate(-2.291 -4.889)"
        fill={fill}
        color={color}
      />
      <path
        d="M1315.269,252.493h-3.691a.9.9,0,0,0,0,1.809h3.691a.9.9,0,0,0,0-1.809Z"
        transform="translate(-0.81 -4.889)"
        fill={fill}
        color={color}
      />
      <path
        d="M1323.421,257.767h-11.844a.9.9,0,0,0,0,1.808h11.844a.9.9,0,1,0,0-1.808Z"
        transform="translate(-0.81 -5.7)"
        fill={fill}
        color={color}
      />
    </g>
  </svg>
);

BoardingIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

BoardingIcon.defaultProps = {
  color: "#000",
  fill: "#03a9f4",
  width: 40.404,
  height: 43.741,
};

export default BoardingIcon;
