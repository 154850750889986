import React from "react";
import PropTypes from 'prop-types';

const FromIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50.216 37.662"
  >
    <g fill={fill} color={color}>
      <path d="M8.37 25.109c-5.689 0-8.37-.207-8.37-2.093 0-.408 0-1.44 3.722-3.517L.176 14.18a1.046 1.046 0 01.132-1.32L2.4 10.768a1.043 1.043 0 011.207-.2l7.9 3.952 5.809-2.9a1.046 1.046 0 11.935 1.871l-6.278 3.138a1.048 1.048 0 01-.935 0l-7.694-3.848-.952.953 3.707 5.563a1.046 1.046 0 01-.391 1.511 21.947 21.947 0 00-3.145 1.942 44.393 44.393 0 005.8.268c7 0 39.754-12.836 39.754-16.738 0-.81-1.987-2.093-7.324-2.093-5.45 0-12.049 3.011-12.116 3.043a1.047 1.047 0 11-.877-1.9c.287-.129 7.078-3.232 12.993-3.232 8.5 0 9.416 2.924 9.416 4.185.007 6.575-36.27 18.826-41.839 18.826z" />
      <path d="M19.877 14.646a1.043 1.043 0 01-.519-.138L4.712 6.139a1.046 1.046 0 01-.222-1.65L8.675.305a1.043 1.043 0 011.04-.261l20.923 6.277a1.046 1.046 0 01.238 1.9l-10.461 6.277a1.05 1.05 0 01-.538.148zM6.938 5.002l12.925 7.386 7.9-4.743L9.711 2.227z" />
      <path d="M40.803 7.319a1.046 1.046 0 01-.735-1.791 10.336 10.336 0 017.01-2.389 1.046 1.046 0 110 2.092 8.284 8.284 0 00-5.551 1.8 1.047 1.047 0 01-.724.288z" />
      <path d="M36.616 31.386a1.04 1.04 0 01-.81-.385L26.39 19.493a1.047 1.047 0 111.621-1.324l8.887 10.859 2.687-1.343-2.934-11.741a1.046 1.046 0 012.029-.506l3.139 12.554a1.048 1.048 0 01-.546 1.189l-4.185 2.092a1.048 1.048 0 01-.472.113z" />
      <path d="M24.062 37.662H1.046a1.046 1.046 0 010-2.092h23.016a1.046 1.046 0 110 2.092z" />
    </g>
  </svg>
);

FromIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FromIcon.defaultProps = {
  color: '#000',
  fill: '#03a9f4',
  width: 50.216,
  height: 37.662,
};

export default FromIcon;
