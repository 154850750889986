import React from "react";
import PropTypes from 'prop-types';

const SeatIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26.754 39.877"
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          color={color}
          fill={fill}
          d="M0 0h26.754v39.877H0z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#prefix__a)" fill={fill} color={color}>
      <path d="M23.983 35.115a2.774 2.774 0 002.771-2.771v-1.335a4.821 4.821 0 00-4.815-4.815H11.1l-.684-2.985H20.8v-1.778H10l-2.442-10.67a4.633 4.633 0 00-4.539-3.617h-.171a2.846 2.846 0 00-2.795 3.4l4.84 24.572h3.55l.794 4.762h13.945V38.1H10.743l-.5-2.985zM6.355 33.338L1.8 10.199a1.071 1.071 0 011.048-1.278h.171a2.865 2.865 0 012.807 2.237l3.853 16.813h12.26a3.041 3.041 0 013.038 3.038v1.335a1 1 0 01-.994.994z" />
      <path d="M18.124 0a6.254 6.254 0 00-6.247 6.247V12.2a6.247 6.247 0 1012.493 0V6.247A6.254 6.254 0 0018.124 0m4.469 12.2a4.469 4.469 0 01-8.938 0v-2.088h8.938zm0-3.865h-3.58V6.35h-1.775v1.985h-3.581V6.247a4.469 4.469 0 018.938 0z" />
    </g>
  </svg>
);

SeatIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

SeatIcon.defaultProps = {
  color: '#000',
  fill: '#03a9f4',
  width: 26.754,
  height: 39.877,
};

export default SeatIcon;
