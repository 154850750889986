import { useState, useEffect } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import FormStage from 'components/pages/parameters/Stages/FormStage';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import useAuth from 'providers/Auth/useAuth';

import {
  get as getStage,
  bulkDelete as deleteStage
} from 'services/API/Stage';

import { prepareTable } from 'utils/func';
import { STAGE_HEADERS } from 'utils/const'

const Stages = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormtype] = useState(null);
  const [selectedStage, setSelectedStage] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, [])

  const getDatas = () => {
    getStage()
      .then(res => {
        setData({ datas: prepareTable(STAGE_HEADERS, res.data), headers: STAGE_HEADERS })
      })
      .catch(e => console.error(e));
  }

  const openModalEdit = (e) => {
    setFormtype('edit');
    setEditId(e.id);
    setModalVisibility(true);
  }

  const openModalAdd = (e) => {
    setFormtype('add');
    setEditId(null);
    setModalVisibility(true);
  }

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  }

  const onDelete = () => {
    if (selectedStage.length > 0) {
      deleteStage({ids: selectedStage.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  }

  return (
    <div className='params crud' id="airports">
      <div className="crud__header">
        <div>
          <h1>Stages</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <Button
          text='Delete'
          disabled={selectedStage.length === 0}
          defaultStyle={`primary${selectedStage.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
        {/* <Button
          text='Export'
          disabled={selectedStage.length === 0}
          defaultStyle={`primary${selectedStage.length === 0 ? ' disabled' : ''}`}
          onClick={() => console.log('Export')}
        /> */}
        <Button
          text='Add stage'
          defaultStyle='primary'
          onClick={openModalAdd}
        />
      </div>
      <Table
        selectedRows={d => setSelectedStage(d)}
        datas={data.datas}
        headers={data.headers}
        editable={true}
        onEdit={openModalEdit}
        name='params/stagesFilters'
      />
      { modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormStage onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      ) }
      { confirmDeletePopup && (
        <ConfirmDelete 
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedStage}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
}

export default Stages;