import API from 'services/API';

export const get = () => API.get('/mission');

export const getById = (id) => API.get(`/mission/${id}`);

export const add = (mission) => API.post('/mission', mission);

export const edit = (mission) => API.patch(`/mission/${mission.id}`, mission);

export const del = (mission) => API.delete(`/mission/${mission}`);

export const bulkDelete = (mission) => API.delete(`/mission/${mission}`);
