import { useState } from 'react';

import Bar from "components/generics/Charts/Bar";

const FuelUsed = ({data, loading}) => {
  const [plannedData, setPlannedData] = useState(false)

  return (
    !loading ? (
      <>
        <div className='highlight_value'>
          <div>
            <span>
              {data?.totalFuelAvailable.toLocaleString()}L
            </span>
            <span>Fuel planned</span>
          </div>
          <div>
            <span>
              {data?.totalFuelUsed.toLocaleString()}L
            </span>
            <span>Fuel burn</span>
          </div>
          <div>
            <span>
              {Math.max(data?.totalFuelRemaining, 0).toLocaleString()}L
            </span>
            <span>Fuel available</span>
          </div>
          <div>
              <label name="switchData">
                <input
                  name="switchData"
                  type="checkbox"
                  onChange={() => {
                      setPlannedData(!plannedData)
                    }
                  }
                  checked={plannedData}
                />
              Unstack bars
            </label>
          </div>
        </div>
        <div className="graph_container">
          <Bar
            data={data?.airports.map((d) => d.fuelRemaining < 0 ? {...d, ...{ fuelRemaining: 0 }} : d)}
            keys={plannedData ? ['fuelAvailable', 'fuelUsed'] : ['fuelUsed', 'fuelRemaining']}
            mode={plannedData? 'grouped' : 'stacked'}
            labels={plannedData ? ['Fuel Planned', 'Fuel used'] : ['Fuel used', 'Fuel remaining']}
            indexBy={'icaoCode'}
            label={!plannedData}
            colors={plannedData ? ["#03A9F4", "#eb5367"] : ["#eb5367", "#03A9F4"]}
          />
        </div>
      </>
    ) : (
      <>
        loading
      </>
    )
  );
}

export default FuelUsed;