import CloseIcon from 'components/icons/CloseIcon';

import './tag.style.scss';

const Tag = ({ title, onDelete}) => (
  <div className='tag'>
    <span className='tag__content'>
      {title} <span onClick={onDelete}><CloseIcon /></span>
    </span>
  </div>
);

export default Tag;