import React from "react";
import PropTypes from 'prop-types';

const ProfileIcon = ({ fill, stroke, width, height }) => (
  <svg width={width} height={height}>
    <g transform="translate(4175 3614)">
      <circle
        cx={18.5}
        cy={18.5}
        r={18.5}
        transform="translate(-4175 -3614)"
      />
      <path
        d="M-4168.779-3581.662a12.891 12.891 0 018.045-9.617 7.782 7.782 0 119.152 0 12.9 12.9 0 017.932 9.089 18.639 18.639 0 01-2.068 1.726 10.507 10.507 0 00-20.921.441 18.546 18.546 0 01-2.14-1.639zm7.173-15.908a5.448 5.448 0 105.447-5.448 5.454 5.454 0 00-5.446 5.448z"
        fill={fill}
        stroke={stroke}
        strokeMiterlimit={10}
      />
    </g>
  </svg>
)

ProfileIcon.propTypes = {
  fill: PropTypes.string,
  storke: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ProfileIcon.defaultProps = {
  fill: '#fff',
  stroke: '#000',
  width: 37,
  height: 37,
};

export default ProfileIcon