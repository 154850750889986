import ResetPasswordForm from 'components/pages/ResetPassword/ResetPasswordForm';

import 'components/pages/Login/Login.style.scss';
import logo from 'assets/images/cps_logo.png'

const ResetPassword = () => (
  <div className='Login'>
    <div className='form'>
      <div className='logoContainer'>
        <img className='logo m-auto d-block' src={logo} alt='logo cps' />
      </div>
      <div className='container'>
        <h1>New password</h1>
        <ResetPasswordForm />
      </div>
    </div>
    <div className='img' />
  </div>
);

export default ResetPassword;
