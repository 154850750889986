import { v1 } from "uuid";

import { formatDate, formatMinutesToReadableHour } from "utils/func";

import DashboardIcon from "components/icons/DashboardIcon";
import DatabaseIcon from "components/icons/DatabaseIcon";
import FlightIcon from "components/icons/FlightIcon";
import StatsIcon from "components/icons/StatsIcon";
import ParametersIcon from "components/icons/ParametersIcon";

import BoardingIcon from "components/icons/BoardingIcon";
import FromIcon from "components/icons/FromIcon";
import ToIcon from "components/icons/ToIcon";
import MissionIcon from "components/icons/MissionIcon";
import AircraftTypeIcon from "components/icons/AircraftTypeIcon";
import SeatIcon from "components/icons/SeatIcon";

/**
 * Nb minutes in a day
 */
export const DAYS_MINUTES = 1_440;

/**
 * Nb milliseconds in a day
 */
export const DAYS_MILLISECONDS = 86_400_000;

/**
 * General nav structure
 * Item :
 * @type {Object}
 * @property {string} id - Item id
 * @property {string} label - Label displayed in the nav
 * @property {string} icon - Icon displayed in the nav
 * @property {string} path - Path to the page
 * @property {string} [subItems] - Sub items
 */
export const ItemsNav = [
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    allowedRoles: ["Admin", "CPSAdmin", "UserCPS", "ASO", "Authority"],
    subItems: false,
  },
  {
    id: 2,
    title: "Flights",
    path: "/flights",
    icon: <FlightIcon />,
    allowedRoles: [
      "Admin",
      "CPSAdmin",
      "CPSUser",
      "Airline",
      "ASO",
      "Authority",
      "Airport",
      "DISPATCH",
      "PASSENGERS",
      "CREW",
    ],
    subItems: [
      {
        id: 2.1,
        title: "All flights",
        path: "/flights/all",
        allowedRoles: [
          "Admin",
          "CPSAdmin",
          "CPSUser",
          "DISPATCH",
          "PASSENGERS",
          "CREW",
        ],
      },
      {
        id: 2.2,
        title: "Active Flights",
        path: "/flights",
        allowedRoles: [
          "Admin",
          "CPSAdmin",
          "CPSUser",
          "Airline",
          "ASO",
          "Authority",
          "Airport",
          "DISPATCH",
          "PASSENGERS",
          "CREW",
        ],
      },
      {
        id: 2.3,
        title: "Canceled flights",
        path: "/flights/cancel",
        allowedRoles: [
          "Admin",
          "CPSAdmin",
          "CPSUser",
          "Airline",
          "ASO",
          "Authority",
          "Airport",
          "DISPATCH",
          "PASSENGERS",
          "CREW",
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Stats",
    path: "/stats",
    icon: <StatsIcon />,
    allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
    subItems: false,
  },
  {
    id: 4,
    title: "Parameters",
    path: "/parameters",
    icon: <ParametersIcon />,
    allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
    subItems: [
      {
        id: 4.1,
        title: "Aircrafts",
        path: "/parameters/aircrafts",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.2,
        title: "Airports",
        path: "/parameters/airports",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.3,
        title: "Users",
        path: "/parameters/users",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.4,
        title: "Airlines",
        path: "/parameters/airlines",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.5,
        title: "Routes",
        path: "/parameters/routes",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.6,
        title: "Missions",
        path: "/parameters/missions",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.7,
        title: "Aircraft Types",
        path: "/parameters/aircrafttypes",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.8,
        title: "Stages",
        path: "/parameters/stages",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
      {
        id: 4.9,
        title: "Delay Types",
        path: "/parameters/delaytypes",
        allowedRoles: ["Admin", "CPSAdmin", "CPSUser"],
      },
    ],
  },
  {
    id: 5,
    title: "Databases",
    path: "/databases",
    icon: <DatabaseIcon />,
    allowedRoles: ["DBA"],
    subItems: false,
  },
];

/**
 * Array of months shorted
 */
export const shortMonth = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUNE",
  "JULY",
  "AUG",
  "SEPT",
  "OCT",
  "NOV",
  "DEC",
];

/**
 * Array of days shorted
 */
export const shortDayName = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

/**
 * Header structure for dashboard tables
 */
export const QuickFlightsHeaders = [
  {
    icon: <BoardingIcon />,
    title: "TAKE OFF TIME (local)",
  },
  {
    icon: <FromIcon />,
    title: "FROM (ICAO)",
  },
  {
    icon: <ToIcon />,
    title: "TO (ICAO)",
  },
  {
    icon: <MissionIcon />,
    title: "MISSION",
  },
  {
    icon: <AircraftTypeIcon />,
    title: "AIRCRAFT TYPE",
  },
  {
    icon: <SeatIcon />,
    title: "SEATS CAPACITY",
  },
];

/**
 * This variable create flight table structure
 */
export const FLIGHT_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Time out from camp",
    accessor: "transferTime",
    bindData: "departureAirport.transferTime",
    format: (data) => (data ? data : "NA"),
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: "aircraft.aircraftType.boardingTime",
    format: (data) => data,
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeUtc",
    format: (data) => formatMinutesToReadableHour(data + 180),
  },
  {
    Header: "Take off time (UTC)",
    accessor: "takeoffTimeUtc",
    bindData: "takeoffTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT TAKE OFF",
    accessor: "takeoffTimeReal",
    bindData: "takeoffTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Delay",
    accessor: "delay",
    bindData: null,
    format: (data) => {
      if (data.landingTimeReal && data.takeoffTimeReal) {
        return data.landingTimeReal - data.takeoffTimeReal;
      } else {
        return null;
      }
    },
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeUtc",
    format: (data) => formatMinutesToReadableHour(data + 180),
  },
  {
    Header: "Landing time (UTC)",
    accessor: "landingTimeUtc",
    bindData: "landingTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT LANDING",
    accessor: "landingTimeReal",
    bindData: "landingTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Flight Time",
    accessor: null,
    bindData: null,
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for CPS roles
 */
export const FLIGHT_HEADERS_ADMIN = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Time out from camp",
    accessor: "timeOutFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally -
              data.aircraft.aircraftType.boardingTime -
              data.departureAirport.transferTime
          ),
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally - data.aircraft.aircraftType.boardingTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Take off time (UTC)",
    accessor: "takeoffTimeUtc",
    bindData: "takeoffTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT TAKE OFF",
    accessor: "takeoffTimeReal",
    bindData: "takeoffTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Delay",
    accessor: "delay",
    bindData: "delayDuration",
    format: (data) => data,
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (UTC)",
    accessor: "landingTimeUtc",
    bindData: "landingTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT LANDING",
    accessor: "landingTimeReal",
    bindData: "landingTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Flight Time",
    accessor: "flightTimeReal",
    bindData: null,
    format: (data) => formatMinutesToReadableHour((data.flightTimeReal + data.departureAirport.taxiingTime + data.arrivalAirport.taxiingTime)),
  },
  {
    Header: "Time in to camp",
    accessor: "timeInFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.landingTimeRally +
              data.aircraft.aircraftType.disembarquingTime +
              data.arrivalAirport.transferTime
          ),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Seats available",
    accessor: "seatsAvailable",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
  {
    Header: "PAX",
    accessor: "passengerCounter",
    bindData: "passengerCounter",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "REG",
    accessor: "Aircraft",
    bindData: "aircraft.reg",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "FROM CIVIL",
    accessor: "fromCivil",
    bindData: "departureAirport.civilCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO CIVIL",
    accessor: "toCivil",
    bindData: "arrivalAirport.civilCode",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "aircraft.callsign",
    format: (data) => data,
  },
  {
    Header: "squawk",
    accessor: "squawk",
    bindData: "aircraft.squawk",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for ASO
 */
export const FLIGHT_HEADERS_ASO = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Time out from camp",
    accessor: "timeOutFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally -
              data.aircraft.aircraftType.boardingTime -
              data.departureAirport.transferTime
          ),
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally - data.aircraft.aircraftType.boardingTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT TAKE OFF",
    accessor: "takeoffTimeReal",
    bindData: "takeoffTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Flight Time",
    accessor: "flightTimeReal",
    bindData: null,
    format: (data) => formatMinutesToReadableHour((data.flightTimeReal + data.departureAirport.taxiingTime + data.arrivalAirport.taxiingTime)),
  },
  {
    Header: "Time in to camp",
    accessor: "timeInFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "N/A"
        : formatMinutesToReadableHour(
            data.landingTimeRally +
              data.aircraft.aircraftType.disembarquingTime +
              data.arrivalAirport.transferTime
          ),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Seats available",
    accessor: "seatsAvailable",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
  {
    Header: "PAX",
    accessor: "passengerCounter",
    bindData: "passengerCounter",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "FROM CIVIL",
    accessor: "fromCivil",
    bindData: "departureAirport.civilCode",
    format: (data) => data,
  },
  {
    Header: "TO CIVIL",
    accessor: "toCivil",
    bindData: "arrivalAirport.civilCode",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for Authorities roles
 */
export const FLIGHT_HEADERS_AUTHORITY = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Take off time (UTC)",
    accessor: "takeoffTimeUtc",
    bindData: "takeoffTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (UTC)",
    accessor: "landingTimeUtc",
    bindData: "landingTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "aircraft.callsign",
    format: (data) => data,
  },
  {
    Header: "squawk",
    accessor: "squawk",
    bindData: "aircraft.squawk",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for Airlines roles
 */
export const FLIGHT_HEADERS_AIRLINE = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Take off time (UTC)",
    accessor: "takeoffTimeUtc",
    bindData: "takeoffTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (UTC)",
    accessor: "landingTimeUtc",
    bindData: "landingTimeUtc",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "REG",
    accessor: "Aircraft",
    bindData: "aircraft.reg",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "aircraft.callsign",
    format: (data) => data,
  },
  {
    Header: "squawk",
    accessor: "squawk",
    bindData: "aircraft.squawk",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for CPS roles
 */
export const FLIGHT_HEADERS_AIRPORT = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally - data.aircraft.aircraftType.boardingTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Delay",
    accessor: "delay",
    bindData: "delayDuration",
    format: (data) => data,
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Seats available",
    accessor: "seatsAvailable",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "REG",
    accessor: "Aircraft",
    bindData: "aircraft.reg",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for DISPATCH role
 */
export const FLIGHT_HEADERS_DISPATCH = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: " camp",
    accessor: "timeOutFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally -
              data.aircraft.aircraftType.boardingTime -
              data.departureAirport.transferTime
          ),
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally - data.aircraft.aircraftType.boardingTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT TAKE OFF",
    accessor: "takeoffTimeReal",
    bindData: "takeoffTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Delay",
    accessor: "delay",
    bindData: "delayDuration",
    format: (data) => data,
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "ACT LANDING",
    accessor: "landingTimeReal",
    bindData: "landingTimeReal",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Flight Time",
    accessor: "flightTimeReal",
    bindData: null,
    format: (data) => formatMinutesToReadableHour((data.flightTimeReal + data.departureAirport.taxiingTime + data.arrivalAirport.taxiingTime)),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Seats available",
    accessor: "seatsAvailable",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
  {
    Header: "PAX",
    accessor: "passengerCounter",
    bindData: "passengerCounter",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "REG",
    accessor: "Aircraft",
    bindData: "aircraft.reg",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "aircraft.callsign",
    format: (data) => data,
  },
  {
    Header: "squawk",
    accessor: "squawk",
    bindData: "aircraft.squawk",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for CREW role
 */
export const FLIGHT_HEADERS_CREW = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally - data.aircraft.aircraftType.boardingTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Flight Time",
    accessor: "flightTimeReal",
    bindData: null,
    format: (data) => formatMinutesToReadableHour((data.flightTimeReal + data.departureAirport.taxiingTime + data.arrivalAirport.taxiingTime)),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "REG",
    accessor: "Aircraft",
    bindData: "aircraft.reg",
    format: (data) => data,
  },
  {
    Header: "FROM ICAO",
    accessor: "fromOACI",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "TO ICAO",
    accessor: "toOACI",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "aircraft.callsign",
    format: (data) => data,
  },
  {
    Header: "squawk",
    accessor: "squawk",
    bindData: "aircraft.squawk",
    format: (data) => data,
  },
];

/**
 * This variable create flight table structure for PASSENGERS role
 */
export const FLIGHT_HEADERS_PASSENGERS = [
  {
    Header: "raw",
    accessor: "raw",
    bindData: null,
    format: (data) => data,
  },
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => {
      return formatDate(new Date(data));
    },
  },
  {
    Header: "Time out from camp",
    accessor: "timeOutFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.takeoffTimeRally -
              data.aircraft.aircraftType.boardingTime -
              data.departureAirport.transferTime
          ),
  },
  {
    Header: "Take off time (rally)",
    accessor: "takeoffTimeRally",
    bindData: "takeoffTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Landing time (rally)",
    accessor: "landingTimeRally",
    bindData: "landingTimeRally",
    format: (data) => formatMinutesToReadableHour(data),
  },
  {
    Header: "Time in to camp",
    accessor: "timeInFromCamp",
    bindData: null,
    format: (data) =>
      !!["DKR MED", "FERRY", "REL"].find((e) => e === data.mission.name)
        ? "NA"
        : formatMinutesToReadableHour(
            data.landingTimeRally +
              data.aircraft.aircraftType.disembarquingTime +
              data.arrivalAirport.transferTime
          ),
  },
  {
    Header: "Mission",
    accessor: "mission",
    bindData: "mission.name",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "AircraftType",
    bindData: "aircraft.aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "FROM CIVIL",
    accessor: "fromCivil",
    bindData: "departureAirport.civilCode",
    format: (data) => data,
  },
  {
    Header: "TO CIVIL",
    accessor: "toCivil",
    bindData: "arrivalAirport.civilCode",
    format: (data) => data,
  },
];

/**
 * This variable create aircraft table structure
 */
export const AIRCRAFT_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "aircraftType",
    bindData: "aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "Reg",
    accessor: "reg",
    bindData: "reg",
    format: (data) => data,
  },
  {
    Header: "PAX",
    accessor: "passengerCapacity",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
  {
    Header: "Airlines",
    accessor: "airline",
    bindData: "airline.name",
    format: (data) => data,
  },
  {
    Header: "Callsign",
    accessor: "callsign",
    bindData: "callsign",
    format: (data) => data,
  },
  {
    Header: "Squawk",
    accessor: "squawk",
    bindData: "squawk",
    format: (data) => data,
  },
];

/**
 * This variable create user table structure
 */
export const USER_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "First name",
    accessor: "firstname",
    bindData: "firstname",
    format: (data) => data,
  },
  {
    Header: "Lastname",
    accessor: "lastname",
    bindData: "lastname",
    format: (data) => data,
  },
  {
    Header: "E-mail",
    accessor: "email",
    bindData: "email",
    format: (data) => data,
  },
  {
    Header: "Phone",
    accessor: "phone",
    bindData: "phone",
    format: (data) => data,
  },
  {
    Header: "Role",
    accessor: "role",
    bindData: null,
    format: (data) => data.userRole.name,
  },
  {
    Header: "Airline",
    accessor: "airline",
    bindData: null,
    format: (data) => data?.airline?.name,
  },
];

/**
 * This variable create airport table structure
 */
export const AIRPORT_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "ICAO code",
    accessor: "icaoCode",
    bindData: "icaoCode",
    format: (data) => data,
  },
  {
    Header: "Civil name",
    accessor: "civilCode",
    bindData: "civilCode",
    format: (data) => data,
  },
  {
    Header: "Taxi time",
    accessor: "taxiingTime",
    bindData: "taxiingTime",
    format: (data) => data,
  },
  {
    Header: "Transfer time",
    accessor: "transferTime",
    bindData: "transferTime",
    format: (data) => data,
  },
  {
    Header: "Time lag(UTC)",
    accessor: "timeLagUtc",
    bindData: "timeLagUtc",
    format: (data) => data,
  },
  {
    Header: "Fuel available(L)",
    accessor: "fuelAvailable",
    bindData: "fuelAvailable",
    format: (data) => data,
  },
];

/**
 * This variable create companie table structure
 */
export const COMPANIE_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Name",
    accessor: "name",
    bindData: "name",
    format: (data) => data,
  },
  {
    Header: "Phone",
    accessor: "contactPhone",
    bindData: "contactPhone",
    format: (data) => data,
  },
  {
    Header: "Email",
    accessor: "contactEmail",
    bindData: "contactEmail",
    format: (data) => data,
  },
  {
    Header: "Lastname contact",
    accessor: "contactLastname",
    bindData: "contactLastname",
    format: (data) => data,
  },
  {
    Header: "Firstname contact",
    accessor: "contactFirstname",
    bindData: "contactFirstname",
    format: (data) => data,
  },
];

/**
 * This variable create route table structure
 */
export const ROUTE_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Label",
    accessor: "label",
    bindData: "label",
    format: (data) => data,
  },
  {
    Header: "Aircraft Type",
    accessor: "aircraftType",
    bindData: "aircraftType.name",
    format: (data) => data,
  },
  {
    Header: "From (ICAO)",
    accessor: "from",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "To (ICAO)",
    accessor: "to",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "Speed(KTS)",
    accessor: "speed",
    bindData: "aircraftType.speed",
    format: (data) => data,
  },
  {
    Header: "Distance(NM)",
    accessor: "distance",
    bindData: "distance",
    format: (data) => data,
  },
  {
    Header: "Flight time",
    accessor: "duration",
    bindData: "duration",
    format: (data) => data,
  },
  {
    Header: "Average flight time",
    accessor: "durationWithTaxiing",
    bindData: "durationWithTaxiing",
    format: (data) => data,
  },
];

/**
 * This variable create stage table structure
 */
export const STAGE_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Name",
    accessor: "name",
    bindData: "name",
    format: (data) => data,
  },
  {
    Header: "Stage date",
    accessor: "date",
    bindData: "date",
    format: (data) => formatDate(new Date(data)),
  },
  {
    Header: "From (ICAO)",
    accessor: "from",
    bindData: "departureAirport.icaoCode",
    format: (data) => data,
  },
  {
    Header: "To (ICAO)",
    accessor: "to",
    bindData: "arrivalAirport.icaoCode",
    format: (data) => data,
  },
];

/**
 * This variable create aircrafttype table structure
 */
export const AIRCRAFTTYPE_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Name",
    accessor: "name",
    bindData: "name",
    format: (data) => data,
  },
  {
    Header: "Speed(KTS)",
    accessor: "speed",
    bindData: "speed",
    format: (data) => data,
  },
  {
    Header: "Boarding time",
    accessor: "boardingTime",
    bindData: "boardingTime",
    format: (data) => data,
  },
  {
    Header: "Offboarding time",
    accessor: "disembarquingTime",
    bindData: "disembarquingTime",
    format: (data) => data,
  },
];

/**
 * This variable create Seats by stage table stats structure
 */
export const SEATS_STATS_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: null,
    format: () => v1(),
  },
  {
    Header: "Date",
    accessor: "date",
    bindData: "date",
    format: (data) => formatDate(new Date(data)),
  },
  {
    Header: "Mission",
    accessor: "missionName",
    bindData: "missionName",
    format: (data) => data,
  },
  {
    Header: "Remaining seats",
    accessor: "seatsAvailable",
    bindData: "seatsAvailable",
    format: (data) => data,
  },
  {
    Header: "Seats used",
    accessor: "passengerCounter",
    bindData: "passengerCounter",
    format: (data) => data,
  },
  {
    Header: "Total capacity",
    accessor: "passengerCapacity",
    bindData: "passengerCapacity",
    format: (data) => data,
  },
];

/*
 * This variable create missions table stats structure
 */
export const MISSIONS_STATS_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Mission",
    accessor: "name",
    bindData: "name",
    format: (data) => data,
  },
  {
    Header: "Number of flight",
    accessor: "flightCounter",
    bindData: "flightCounter",
    format: (data) => data,
  },
  {
    Header: "Seats available",
    accessor: "passengerCapacityTotal",
    bindData: "passengerCapacityTotal",
    format: (data) => data,
  },
  {
    Header: "Seats used",
    accessor: "passengerCounterTotal",
    bindData: "passengerCounterTotal",
    format: (data) => data,
  },
  {
    Header: "PAX/Flight average",
    accessor: "averagePassengersPerFlight",
    bindData: "averagePassengersPerFlight",
    format: (data) => (data ? data : "NA"),
  },
  {
    Header: "Seat unused/Flight average",
    accessor: "averageSeatsAvailablePerFlight",
    bindData: "averageSeatsAvailablePerFlight",
    format: (data) => data,
  },
  {
    Header: "DLA number",
    accessor: "delayCounter",
    bindData: "delayCounter",
    format: (data) => data,
  },
  {
    Header: "DLA/Flight average",
    accessor: "averageDelayPerFlight",
    bindData: null,
    format: (data) =>
      data.flightCounter
        ? (data.delayCounter / data.flightCounter).toFixed(2)
        : "NA",
  },
  {
    Header: "DLA duration/Flight average",
    accessor: "averageDelayDurationsPerFlight",
    bindData: null,
    format: (data) =>
      data.flightCounter
        ? (data.delayDurationTotal / data.flightCounter).toFixed(2)
        : "NA",
  },
  {
    Header: "Departure in advance",
    accessor: "delayClassInAdvanceCounter",
    bindData: "delayClassInAdvanceCounter",
    format: (data) => data,
  },
  {
    Header: "Departure on time",
    accessor: "delayClassOnTimeCounter",
    bindData: "delayClassOnTimeCounter",
    format: (data) => data,
  },
  {
    Header: "Delay < 30'",
    accessor: "delayClassDelayInf30Counter",
    bindData: "delayClassDelayInf30Counter",
    format: (data) => data,
  },
  {
    Header: "Delay < 30' - 1:00",
    accessor: "delayClassDelayBetween3060Counter",
    bindData: "delayClassDelayBetween3060Counter",
    format: (data) => data,
  },
  {
    Header: "Delay > 1:00",
    accessor: "delayClassDelaySup60Counter",
    bindData: "delayClassDelaySup60Counter",
    format: (data) => data,
  },
];

/*
 * This variable create Seats by stage table stats structure
 */
export const FLIGHTIMES_STATS_HEADERS = [
  {
    Header: "id",
    accessor: "id",
    bindData: "id",
    format: (data) => data,
  },
  {
    Header: "Reg",
    accessor: "reg",
    bindData: "reg",
    format: (data) => data,
  },
  {
    Header: "Planned flight hours",
    accessor: "totalHoursFlightTimeUtc",
    bindData: "totalHoursFlightTimeUtc",
    format: (data) => data,
  },
  {
    Header: "Tlb flight hours",
    accessor: "totalHoursFlightTimeTlb",
    bindData: "totalHoursFlightTimeTlb",
    format: (data) => data,
  },
];

/*
 * This variable create Seats by stage table stats structure
 */
export const DATABASES_HEADERS = [
  {
    Header: "name",
    accessor: "db",
    bindData: "db",
    format: (data) => data,
  },
];
