import { useEffect, useState } from 'react';

import FuelUsed from 'components/pages/Stats/Fuel/FuelUsed';
import FuelPlanned from 'components/pages/Stats/Fuel/FuelPlanned';

import './FuelStats.style.scss';

import { fuel } from 'services/API/Stats';

const FuelStats = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fuel()
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => console.error(err))
  }, [])

  return (
    <div id="FuelStats" className="statsContainer">
      <div className="statsSection" id="fuelUsed">
        <h3>Fuel</h3>
        <FuelUsed data={data} loading={loading} />
      </div>
      <div className="statsSection" id="fuelPlanned">
        <h3>Fuel planned</h3>
        <FuelPlanned data={data} loading={loading} />
      </div>
    </div>
  )
}

export default FuelStats