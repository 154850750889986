import PropTypes from 'prop-types';

const EditIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    color={color}
    fill={fill}
  >
    <path d="M19.769 1.231a4.2 4.2 0 00-5.942 0L2.084 12.974a.82.82 0 00-.21.36L.03 19.961a.819.819 0 001.014 1.009l6.627-1.882a.82.82 0 00.357-1.368l-4.177-4.19 9.734-9.734 3.62 3.62-7.452 7.431a.82.82 0 001.159 1.162l8.859-8.835a4.2 4.2 0 000-5.942zM5.881 17.89l-3.879 1.1 1.086-3.9zM18.609 6.012l-.245.244-3.622-3.622.244-.243a2.561 2.561 0 013.622 3.621z" />
  </svg>
);

EditIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

EditIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 21,
  height: 21,
};

export default EditIcon;
