import { useEffect, useState } from 'react';

import Table from 'components/generics/Table/ReactTable';

import { missions } from 'services/API/Stats';
import { prepareTable } from 'utils/func';
import { MISSIONS_STATS_HEADERS } from 'utils/const';

const MissionsStats = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    missions()
      .then(res => {
        setData({ datas: prepareTable(MISSIONS_STATS_HEADERS, res.data.missions), headers: MISSIONS_STATS_HEADERS })
        setLoading(false);
      })
      .catch(err => console.error(err))
  }, [])

  return (
    <div id="MissionsStats" className="statsContainer">
        { loading ? (
          <div>Loading</div>
        ) : (
          <Table
            selectedRows={() => {}}
            datas={data.datas}
            headers={data.headers}
            filters={false}
            selectable={false}
            name='missionsFilters'
          />
        )}
    </div>
  );
};

export default MissionsStats;