import { useEffect, useState } from 'react';

import DelaysClassContainer from 'components/pages/Stats/Delay/DelaysClass';
import DelaysTypeContainer from 'components/pages/Stats/Delay/DelaysType';

import './DelayStats.style.scss';

import { classDelays, typeDelays } from 'services/API/Stats';

const DelayStats = () => {
  const [classDelaysData, setClassDelaysData] = useState(null)
  const [loadingClass, setLoadingClass] = useState(true)
  const [typeDelaysData, setTypeDelaysData] = useState(null)
  const [loadingType, setLoadingType] = useState(true)


  useEffect(() => {
    classDelays()
      .then(res => {
        setClassDelaysData(res.data.delayClasses.filter(d => d.class !== ''));
        setLoadingClass(false);
      })
      .catch(err => console.error(err))
    typeDelays()
      .then(res => {
        setTypeDelaysData(res.data);
        setLoadingType(false);
      })
      .catch(err => console.error(err))
  }, [])

  return (
    <div id="DelayStats" className="statsContainer">
      <div className="statsSection" id="Delays">
        <h3>Delays</h3>
        <DelaysClassContainer data={classDelaysData} loading={loadingClass} />
      </div>
      <div className="statsSection" id="DelaysImputation">
        <h3>Delays imputation</h3>
        <DelaysTypeContainer data={typeDelaysData} loading={loadingType} />
      </div>
    </div>
  );
};

export default DelayStats;