import Pie from 'components/generics/Charts/Pie';

const SeatUsedContainer = ({ data, loading }) => (
  !loading ? (
    <>
      <div className='highlight_value'>
        <div>
          <span>
            {data?.find(v => v.class === "Surbook").counterPercent}%
          </span>
          <span>
            Surbook
          </span>
        </div>
        <div>
          <span>
            {data?.find(v => v.class === "Full").counterPercent}%
          </span>
          <span>
            Full
          </span>
        </div>
      </div>
      <div className="graph_container">
        <Pie
          data={data}
          label="id"
          id="class"
          value="counter"
          colors={["#53B7F3", '#275672', '#9BD2F3', '#79A5BE']}
          />
      </div>
    </>
  ) : (
    <>
      loading
    </>
  )
);

export default SeatUsedContainer;