import { ResponsivePie } from "@nivo/pie";

const Pie = ({ data, colors, label = 'label', id = 'id', value = 'value' }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    colors={colors}
    id={id}
    value={value}
    innerRadius={0.7}
    arcLinkLabel={label}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#000"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsTextColor="#fff"
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#000',
        itemDirection: 'top-to-bottom',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'square',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />
)

export default Pie;
