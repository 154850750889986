import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

import { v1 } from 'uuid';

import { Link } from 'react-router-dom';

const MenuItem = ({ active, path, expanded, icon, title, subItems }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false)

  return (
    <div className={`item_container${open ? ' open' : ''}`}>
      { !subItems ?
        (
          <Link title={title} to={path} className={`item${active ? ' active' : ''}`}>
            {React.cloneElement(icon, { fill: active ? '#03A9F4' : '#fff' })}
            {expanded && (
              <span>{title}</span>
              )}
          </Link>
        ) : (
          <div
            title={title}
            to={path}
            className={`item${active ? ' active' : ''}`}
            onClick={() => setOpen(!open)}
          >
            {React.cloneElement(icon, { fill: active ? '#03A9F4' : '#fff' })}
            {expanded && (
              <span>{title}</span>
              )}
          </div>
        )
      }
      {subItems && (
        <div className='subItems'>
          { !expanded && <div className='subItemTitle'>{title}</div> }
          { subItems.map(i => (
            <Link
              title={i.title}
              to={i.path}
              key={v1()}
              className={location.pathname === i.path ? 'active' : ''}
            >
              {i.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  expanded: PropTypes.bool,
  icon: PropTypes.element
};

MenuItem.defaultProps = {
  active: false,
  expanded: false
};

export default MenuItem;