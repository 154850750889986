import React, { useEffect, useState } from 'react'
import "./Datasource.style.scss";
import logo from "assets/images/cps_logo.png";
import { getDatabases } from 'services/API/Database';
import Select from 'components/generics/Select/Select';
import Button from 'components/generics/Button/Button';
import userEvent from '@testing-library/user-event';
import useAuth from "providers/Auth/useAuth";
import { Redirect } from "react-router-dom";
import { get, updateDatasource } from 'services/API/User';



const Datasource = () => {
    const [databases, setDatabases] = useState([])
    const { logout, user } = useAuth();
    const [selectedDatabase, setSelectedDatabase] = useState("")

    useEffect(() => {
        getDatabases().then((dbs) => {
            setDatabases([{db: ""}, ...dbs.data.filter((db) => db?.db != "cps_auth")])
        })
        .catch((error) => {
            console.error("An error occured while fetching all databases :"+error)
        })
    }, [])

    const selectedDb = () => {
        console.log(user)
        console.log(selectedDatabase)
        if(selectedDatabase != "") {
            updateDatasource({email: user.email, datasource: selectedDatabase}).then((result) => {
                logout()
            })
            .catch((error) => {
                console.error("An error occured while selecting database :"+error)
            })
        }
    }

    return user ? (
        <div className="pageds">
             <Button
              text='Logout'
              defaultStyle='primary less_padding logout'
              onClick={logout}/>
            <img className="logo" src={logo} alt="logo cps" />
            {
                user?.roleName == "Admin" || user?.roleName == "CPSAdmin" ?
                    <>
                        <h1>Selected your working database</h1>
                        <Select
                            name="Dabatases"
                            options={databases}
                            value={selectedDatabase}
                            itemValue="db"
                            itemLabel="db"
                            className="select"
                            onChange={(value) =>{setSelectedDatabase(value)}}/>
                        <Button
                            defaultStyle="d-block primary less_padding button"
                            type="submit"
                            text="Select database"
                            onClick={() => selectedDb()}/>
                    </>
                :
                    <h1>Your user have no database allocated. Contact your administrator.</h1>
            }
        </div>
    ) :
        <Redirect to="/login" />
}

export default Datasource