import { useState } from "react";
import { Link } from "react-router-dom";

import JWTService from "services/JWT";
import useAuth from "providers/Auth/useAuth";

import InputLabel from "components/generics/InputLabel/InputLabel";
import Input from "components/generics/Input/Input";
import Button from "components/generics/Button/Button";
import { toastErrors, updateForm } from "utils/func";
import jwtDecode from "jwt-decode";

const DEFAULT_FORM = { email: "", password: "" };

const LoginForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    JWTService.signInWithCredentials(formData.email, formData.password)
      .then((response) => {
        setUser(jwtDecode(response.data.accessToken));
        setLoading(false);
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        toastErrors(err.message);
        setLoading(false);
        updateForm(formData, setFormData, "password", "");
      });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel label="Email" name="email">
          <Input
            name="email"
            value={formData.email}
            type="string"
            placeholder="Enter email"
            defaultStyle="primary"
            onChange={(value) =>
              updateForm(formData, setFormData, "email", value)
            }
            disabled={loading}
          />
        </InputLabel>
        <InputLabel label="Password" name="password">
          <Input
            name="password"
            value={formData.password}
            type="password"
            placeholder="Enter password"
            defaultStyle="primary"
            onChange={(e) => {
              updateForm(formData, setFormData, "password", e);
            }}
            disabled={loading}
          />
        </InputLabel>
        <span>
          <Link to="/forgotPassword">Forgot your password?</Link>
          <Button
            defaultStyle="d-block primary less_padding"
            type="submit"
            text="Login"
            disabled={loading}
          />
        </span>
      </form>
    </>
  );
};

export default LoginForm;
