import Button from "components/generics/Button/Button";
import Input from "components/generics/Input/Input";
import InputLabel from "components/generics/InputLabel/InputLabel";
import Select from "components/generics/Select/Select";
import DeleteIcon from "components/icons/DeleteIcon";
import React, { useEffect, useState } from "react";
import { createDatabase, switchDatabase } from "services/API/Database";
import { get, updateDatasource } from "services/API/User";
import { v1 } from "uuid";
import { updateForm } from "utils/func";
import "./Database.style.scss"

function FormDatabase({ onSubmit, type, value }) {

  const DEFAULT_VALUES = {
    name: ""
  };

  const [formDatas, setFormDatas] = useState(value || DEFAULT_VALUES);
  const [isActivate, setIsActivate] = useState(null)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState("")

  useEffect(() => {
    get().then((data) => {
      setUsers(["",...data.data])
    })
    .catch((error) => {
      console.error(`Error while getting all users : ${error}`)
    })
  }, []);

  const validateData = () => {
    if(type == "add") {
      formDatas.year = +formDatas.year
      createDatabase(formDatas).then((result) => {
        onSubmit();
      })
      .catch((error) => {
        console.error('Error while creating new database :', error);
      });
    } 
  };

  const addDatasourceToUser = (user = null, datasource = value.db) => {
    if(user) {
      setSelectedUser(user.id)
    }
    if(selectedUser) {
      updateDatasource({email: users.filter((u) => u.id == selectedUser)[0].email, datasource: datasource}).then((_) => {
        get().then((data) => {
          setUsers(data.data)
        })
        .catch((error) => {
          console.error(`Error while getting all users : ${error}`)
        })
      })
      .catch((error) => {
        console.error('Error while adding datasource to user :', error);
      });
    }
  }

  const action = () => (type === "edit" ? "Edit" : "Add a");

  return (
    <div className="modal__content">
      <h2>{action()} database</h2>
      <div className="form_database">
        {
          type === "add" ?
            <>
              <InputLabel label="Database Name" name="databaseName">
                <Input
                  name="databaseName"
                  type="string"
                  onChange={(value) =>
                    updateForm(formDatas, setFormDatas, "name", value)
                  }
                  value={formDatas.name}
                />
              </InputLabel>
            </>
          :
            <>
              <h1 className="title">Add user to the database</h1>
              <Select
                  name="users"
                  options={users}
                  value={selectedUser}
                  itemValue="id"
                  itemLabel="email"
                  className="select"
                  onChange={(value) =>{setSelectedUser(value)}}/>
              <Button
                text={`Add user`}
                disabled={false}
                className="button"
                defaultStyle={`primary`}
                onClick={(e) => {addDatasourceToUser()}}/>
            </>
        }
      </div>
      {
        type != "add" ?
          <div className="form_database table">
            <table className="user_table">
              <thead>
                  <tr>
                      <th>Email</th>
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Role</th>
                      <th>Remove</th>
                  </tr>
              </thead>
              <tbody>
                {
                  users.filter((u) => u.datasource == value?.db).map((user, idx) => (
                    <tr key={v1()}>
                      <td>{user.email}</td>
                      <td>{user.firstname}</td>
                      <td>{user.lastname}</td>
                      <td>{user.userRole.name}</td>
                      <td><div className="delete" onClick={() => addDatasourceToUser(user, "")}><DeleteIcon fill={"red"}/></div></td>
                    </tr>
                  ))   
                }
              </tbody>
            </table>
          </div>
        :
          <></>
      }
      {
        type == "add" ?
          <Button
            text={`${action()} database`}
            disabled={false}
            defaultStyle={`primary`}
            onClick={(e) => validateData(e)}
          />
        :
          <></>
      }
    </div>
  );
}

export default FormDatabase;
