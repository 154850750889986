/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import InputLabel from "components/generics/InputLabel/InputLabel";
import Input from "components/generics/Input/Input";
import Button from "components/generics/Button/Button";

import { updateForm } from "utils/func";

import {
  getById as getAircraftTypeById,
  add as addAircraftType,
  edit as editAircraftType,
} from "services/API/AircraftType";

const FormAircraftType = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    name: "",
    boardingTime: "",
    disembarquingTime: "",
    capacity: "",
    speed: ""
  }

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);

  useEffect(() => {
    if (type === "edit") {
      getAircraftTypeById(editId)
        .then((res) => {
          setFormDatas({
            id: res.data.id,
            name: res.data.name,
            boardingTime: res.data.boardingTime,
            disembarquingTime: res.data.disembarquingTime,
            speed: res.data.speed
          });
        })
        .catch((e) => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === "edit") {
      editAircraftType(formDatas)
        .then((e) => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch((err) => console.log(err));
    } else {
      addAircraftType(formDatas)
        .then((e) => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch((err) => console.log(err));
    }
  };

  const action = () => (type === "edit" ? "Edit" : "Add an");

  return (
    <div className="modal__content">
      <h2>{action()} Aircraft type</h2>
      <div className="form-part">
        <InputLabel label="Name" name="name">
          <Input
            name="name"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "name", value)
            }
            value={formDatas.name}
          />
        </InputLabel>
        <InputLabel label="Boarding time" name="boardingTime">
          <Input
            name="boardingTime"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "boardingTime", value)
            }
            value={formDatas.boardingTime}
          />
        </InputLabel>
        <InputLabel label="Offboarding time" name="Offboardingtime">
          <Input
            name="Offboardingtime"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "disembarquingTime", value)
            }
            value={formDatas.disembarquingTime}
          />
        </InputLabel>
        <InputLabel label="Speed (KTS)" name="speed">
          <Input
            name="speed"
            type="number"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "speed", value)
            }
            value={formDatas.speed}
          />
        </InputLabel>
        <InputLabel label="Capacity" name="capacity">
          <Input
            name="capacity"
            type="number"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "capacity", +value)
            }
            value={formDatas.capacity}
          />
        </InputLabel>
      </div>
      <Button
        text={`${action()} Aircraft type`}
        disabled={false}
        defaultStyle={`primary`}
        onClick={(e) => validateData(e)}
      />
    </div>
  );
};

export default FormAircraftType;
