import PropTypes from 'prop-types';

const DelayIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 45.79 44.243"
  >
    <g
      color={color}
      fill={fill}
      id="delays"
      transform="translate(-1081.632 -222.098)"
    >
      <path
        id="Trac\xE9_174"
        data-name="Trac\xE9 174"
        d="M1116.692,233.22V229.4h-2.239v4.814l4.751,4.275,1.5-1.665Z"
        fill="#03a9f4"
      />
      <path
        id="Trac\xE9_175"
        data-name="Trac\xE9 175"
        d="M1123.563,251.692a3.859,3.859,0,1,0,0-7.718h-.678l-.265-.459.159-.164a11.848,11.848,0,1,0-18.566-12.772l-.152.516-2.611-3.975h-7.033l2.071,14.115h-3.735l-4.109-8.218h-7.012l2.087,14.09,6.622,4.585h5.42l-.073.307-3.347,14.342h8.1l9.335-14.649ZM1106.2,231.837a9.664,9.664,0,0,1,7.97-7.4l.286-.042v1.765h2.239V224.4l.287.042a9.663,9.663,0,0,1,7.969,7.4l.069.3H1122.9v2.239h2.277l-.021.269a9.584,9.584,0,0,1-4.092,7.184l-.126.089-.136-.074a5.13,5.13,0,0,0-2.439-.614h-7.646l-4.5-6.854h2.029v-2.239h-2.117Zm-5.956-2.478,7.8,11.876h-9.285l-1.743-11.876Zm-9.2,20.094-5.249-3.634-1.565-10.563h3.032l4.109,8.218h26.994a2.843,2.843,0,0,1,2.56,1.582l.579,1.157h2.062a1.62,1.62,0,0,1,0,3.24h-12.369l.248-.382v-2.358h-14.518l-.641,2.74Zm8.167,14.648h-4.045l.072-.307,3.465-14.841h10.163Z"
        fill="#03a9f4"
      />
    </g>
  </svg>
);

DelayIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DelayIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 40.989,
  height: 49.968,
};

export default DelayIcon;
