import Pie from 'components/generics/Charts/Pie';

const FuelPlanned = ({ data, loading }) => (
  !loading ? (
    <>
      <div className='highlight_value'>
        <div>
          <span>
            {data?.totalFuelAvailable.toLocaleString()}L
          </span>
          <span>planned</span>
        </div>
        <div>
          <span>
            {data?.totalFuelUsed.toLocaleString()}L
          </span>
          <span>used</span>
        </div>
      </div>
      <div className="graph_container">
        <Pie
            data={[
              {
                id: "fuelUsed",
                value: data.totalFuelUsed,
                label: "Fuel used",
              },
              {
                id: "fuelPlanned", 
                value: data.totalFuelAvailable,
                label: "Fuel planned",
              }
            ]}
            colors={["#53B7F3", '#275672']}
          />
      </div>
    </>
  ) : (
    <>
      loading
    </>
  )
);

export default FuelPlanned;