import { useState, useEffect } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import FormAircraft from 'components/pages/parameters/Aircrafts/FormAircraft';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import useAuth from 'providers/Auth/useAuth';


import {
  get as getAircraft,
  bulkDelete as deleteAircraft
} from 'services/API/Aircraft';

import { prepareTable } from 'utils/func';
import { AIRCRAFT_HEADERS } from 'utils/const'

const Aircrafts = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormtype] = useState(null);
  const [selectedAircraft, setSelectedAircraft] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, [])

  const getDatas = () => {
    getAircraft()
      .then(res => {
        setData({ datas: prepareTable(AIRCRAFT_HEADERS, res.data), headers: AIRCRAFT_HEADERS })
      })
      .catch(e => console.error(e));
  }

  const openModalEdit = (e) => {
    setFormtype('edit');
    setEditId(e.id);
    setModalVisibility(true);
  }

  const openModalAdd = (e) => {
    setFormtype('add');
    setEditId(e.id);
    setModalVisibility(true);
  }

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  }
  
  const onDelete = () => {
    if (selectedAircraft.length > 0) {
      deleteAircraft({ids: selectedAircraft.map(v => +v.original.id)})
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  }

  return (
    <div className='params crud' id="aircrafts">
      <div className="crud__header">
        <div>
          <h1>Aircraft</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <Button
          text='Delete'
          disabled={selectedAircraft.length === 0}
          defaultStyle={`primary${selectedAircraft.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
        {/* <Button
          text='Export'
          disabled={selectedAircraft.length === 0}
          defaultStyle={`primary${selectedAircraft.length === 0 ? ' disabled' : ''}`}
          onClick={() => console.log('Export')}
        /> */}
        <Button
          text='Add an aircraft'
          defaultStyle='primary'
          onClick={openModalAdd}
        />
      </div>
      <Table
        selectedRows={d => setSelectedAircraft(d)}
        datas={data.datas}
        headers={data.headers}
        editable={true}
        onEdit={openModalEdit}
        name='aircraftsFilters'
      />
      { modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormAircraft onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      ) }
      { confirmDeletePopup && (
        <ConfirmDelete 
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedAircraft}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
}

export default Aircrafts;