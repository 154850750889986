import Tab from 'components/generics/Tabs/Tab';

import './tabs.scss';

const Tabs = ({ url }) => (
  <div className='TabsContainer'>
    <Tab
      url={url}
      title='Delays'
      path='delays'
    />
    <Tab
      url={url}
      title='Seats optimization'
      path='seats'
    />
    <Tab
      url={url}
      title='Fuel report'
      path='fuel'
    />
    <Tab
      url={url}
      title='Missions'
      path='missions'
    />
    <Tab
      url={url}
      title='Aircraft flight times'
      path='flightTime'
    />
  </div>
);

export default Tabs;