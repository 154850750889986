import React from "react";
import PropTypes from 'prop-types';

const CloseIcon = ({ color, fill, width, height }) => (
  <svg
    viewBox="0 0 28.328 28.328"
    width={width}
    height={height}
    className='closeIcon'
  >
    <path
      data-name="Trac\xE9 17"
      d="M20.892 19.84l-5.677-5.677 5.677-5.677a.743.743 0 00-1.05-1.05l-5.678 5.676-5.677-5.677a.743.743 0 10-1.051 1.05l5.674 5.675-5.677 5.678a.743.743 0 101.05 1.05l5.678-5.677 5.678 5.678a.743.743 0 001.05-1.051zm0 0"
      fill={fill}
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
)

CloseIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CloseIcon.defaultProps = {
  color: '#fff',
  fill: '#fff',
  width: 28.328,
  height: 28.328,
};

export default CloseIcon