import { useEffect, useState } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Pie from 'components/generics/Charts/Pie';
import { FLIGHTIMES_STATS_HEADERS } from 'utils/const';
import { prepareTable } from 'utils/func';

import './FlightTimesStats.style.scss';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';

import { flightTimes } from 'services/API/Stats';

const FlightTimesStats = () => {
  const [data, setData] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [filter, setFilter] = useState('');
  const [dispatchFilter, setDispachFilter] = useState(false);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    flightTimes()
      .then(res => {
        setData({ datas: prepareTable(FLIGHTIMES_STATS_HEADERS, res.data.aircrafts), headers: FLIGHTIMES_STATS_HEADERS })
        setLoading(false);
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    if(data) {
      setFilteredData(data.datas.filter(item => item.reg.toLowerCase().includes(filter.toLowerCase())));
    }
  } , [filter, data])

  const formatChartData = (data) => (data.map(v => ({ id: v.id, value: v.totalHoursFlightTimeUtc, label: v.reg })));

  return (
    <div id="FlightTimesStats" className="statsContainer">
        { loading ? (
          <div>Loading</div>
        ) : (
          <div className="statsContainer">
            <div className='statsSection' id='table'>
              <InputLabel
                label='Reg filter'
                name='reg'
              >
                <Input
                  type='text'
                  name='reg'
                  placeholder='Enter a registration'
                  defaultStyle='primary'
                  onChange={setFilter}
                />
              </InputLabel>
              <Table
                selectedRows={() => {}}
                datas={filteredData}
                headers={data.headers}
                filters={false}
                selectable={false}
                name='flightTimesFilters'
              />
            </div>
            <div className='statsSection' id='chart'>
              <h3>Aircraft hours planned</h3>
              <div>
                <label name="switchData">
                  <input
                    name="switchData"
                    type="checkbox"
                    onChange={() => {
                      setDispachFilter(!dispatchFilter)
                    }}
                    checked={dispatchFilter}
                  /> Use table filter
                </label>
              </div>
              <div className="graph_container">
                <Pie
                  data={dispatchFilter ? formatChartData(filteredData) : formatChartData(data.datas)}
                  colors={[
                    "#9adefe",
                    "#028bca",
                    "#4ec6fd",
                    "#029ce3",
                    "#01577e",
                    "#1cb5fd",
                    "#03A9F4",
                    "#81d6fe",
                    "#35befd",
                    "#026897",
                    "#0279b1",
                    "#68cefd",
                  ]}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default FlightTimesStats;