import React from "react";
import PropTypes from 'prop-types';

const FuelIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 42 42"
  >
    <g fill={fill} color={color} transform="translate(5, 5)">
      <path d="M2.021 28.125v5.362a1.011 1.011 0 001.011 1.011h4.043a1.011 1.011 0 001.011-1.011v-5.362a3.048 3.048 0 001.848-1.848h5.227a5.059 5.059 0 005.054-5.054V9.933a3.018 3.018 0 00-.3-1.308l2.562-2.561h6.9a1.011 1.011 0 001.011-1.011V1.007a1.01 1.01 0 00-1.011-1.011h-8.153a3.012 3.012 0 00-2.144.888l-3.45 3.45a3.013 3.013 0 00-1.308-.3A14.34 14.34 0 00-.001 18.357v6.9a3.037 3.037 0 002.022 2.868zm4.043 4.351H4.043v-4.177h2.021zm12.128-11.252a3.035 3.035 0 01-3.032 3.032h-5.053v-6.064h2.021v1.015a3.035 3.035 0 003.032 3.032 1.011 1.011 0 000-2.021 1.012 1.012 0 01-1.011-1.011v-1.011h4.043zM20.51 2.318a1.017 1.017 0 01.715-.3h7.142v2.021h-6.305a1.011 1.011 0 00-.715.3l-2.736 2.736-1.429-1.429zM2.021 18.366a12.316 12.316 0 0112.3-12.3 1 1 0 01.715.3l2.858 2.859a1 1 0 01.3.715v6.238h-9.1a1 1 0 00-.393.08 1.009 1.009 0 00-.618.931v8.085a1.012 1.012 0 01-1.011 1.011h-4.04a1.012 1.012 0 01-1.011-1.011z" />
      <path d="M29.444 10.107a1.01 1.01 0 00-.875.506l-3.585 6.215-.019.036a5.054 5.054 0 108.959 0c-.006-.013-.013-.024-.02-.036l-3.584-6.215a1.012 1.012 0 00-.876-.506zm0 12.128a3.036 3.036 0 01-2.7-4.417l2.7-4.677 2.7 4.677a3.037 3.037 0 01-2.7 4.417z" />
      <path d="M11.118 10.106h2.021a1.011 1.011 0 000-2.021h-2.021a1.011 1.011 0 000 2.021z" />
      <path d="M11.118 14.149h2.021a1.011 1.011 0 000-2.021h-2.021a1.011 1.011 0 000 2.021z" />
    </g>
  </svg>
);

FuelIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FuelIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 34.502,
  height: 34.502,
};

export default FuelIcon;
