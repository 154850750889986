/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import InputLabel from "components/generics/InputLabel/InputLabel";
import Input from "components/generics/Input/Input";
import Button from "components/generics/Button/Button";

import { updateForm } from "utils/func";

import {
  getById as getCompanieById,
  add as addCompanie,
  edit as editCompanie,
} from "services/API/Airline";

const FormCompanie = ({ onSubmit, editId, type }) => {
  const DEFAULT_VALUES = {
    name: "",
    contactFirstname: "",
    contactLastname: "",
    contactEmail: "",
    contactPhone: ""
  };

  const [formDatas, setFormDatas] = useState(DEFAULT_VALUES);

  useEffect(() => {
    if (type === "edit") {
      getCompanieById(editId)
        .then((res) => {
          setFormDatas({
            id: res.data.id,
            name: res.data.name,
            contactFirstname: res.data.contactFirstname,
            contactLastname: res.data.contactLastname,
            contactEmail: res.data.contactEmail,
            contactPhone: res.data.contactPhone
          });
        })
        .catch((e) => console.error(e));
    }
  }, []);

  const validateData = () => {
    if (type === "edit") {
      editCompanie(formDatas)
        .then((e) => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch((err) => console.log(err));
    } else {
      addCompanie(formDatas)
        .then((e) => {
          setFormDatas(DEFAULT_VALUES);
          onSubmit();
        })
        .catch((err) => console.log(err));
    }
  };

  const action = () => (type === "edit" ? "Edit" : "Add an");

  return (
    <div className="modal__content">
      <h2>{action()} companie</h2>
      <div className="form-part">
        <InputLabel label="Name" name="name">
          <Input
            name="name"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "name", value)
            }
            value={formDatas.name}
          />
        </InputLabel>
        <InputLabel label="Firstname" name="contactFirstname">
          <Input
            name="contactFirstname"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "contactFirstname", value)
            }
            value={formDatas.contactFirstname}
          />
        </InputLabel>
        <InputLabel label="Lastname" name="contactLastname">
          <Input
            name="contactLastname"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "contactLastname", value)
            }
            value={formDatas.contactLastname}
          />
        </InputLabel>
        <InputLabel label="Email" name="contactEmail">
          <Input
            name="contactEmail"
            type="email"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "contactEmail", value)
            }
            value={formDatas.contactEmail}
          />
        </InputLabel>
        <InputLabel label="Phone" name="contactPhone">
          <Input
            name="contactPhone"
            type="text"
            onChange={(value) =>
              updateForm(formDatas, setFormDatas, "contactPhone", value)
            }
            value={formDatas.contactPhone}
          />
        </InputLabel>
      </div>
      <Button
        text={`${action()} companie`}
        disabled={false}
        defaultStyle={`primary`}
        onClick={(e) => validateData(e)}
      />
    </div>
  );
};

export default FormCompanie;
