import API from 'services/API';

export const get = () => API.get('/aircraft');

export const getById = (id) => API.get(`/aircraft/${id}`);

export const add = (aircraft) => API.post('/aircraft', aircraft);

export const edit = (aircraft) => API.put(`/aircraft/${aircraft.id}`, aircraft);

export const bulkDelete = (aircraft) => API.delete('/aircraft/byBulk', { data: aircraft });
