/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';
import { updateForm, toastErrors } from 'utils/func'

import { resetPassword } from 'services/API/User';

const DEFAULT_FORM = { password: '', passwordConfirm: '', resetToken: '' };

const ResetPasswordForm = () => {
  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    updateForm(formData, setFormData, 'resetToken', query.get('resetToken'));
  }, [])

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    resetPassword(formData)
      .then((response) => {
        toast.success('Password changed !')
        setLoading(false);
        history.push("login");
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        setLoading(false);
        setFormData({...DEFAULT_FORM, ...{resetToken: query.get('resetToken')}});
        toastErrors(err.response.data.message, false);
      });
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <InputLabel
          label='Password'
          name='password'
        >
          <Input
            name='password'
            value={formData.password}
            type='password'
            placeholder='Enter new password'
            defaultStyle="primary"
            onChange={(value) => updateForm(formData, setFormData, 'password', value)}
            disabled={loading}
          />
        </InputLabel>
        <InputLabel
          label='Confirm password'
          name='passwordConfirm'
        >
          <Input
            name="passwordConfirm"
            value={formData.passwordConfirm}
            type="password"
            placeholder="Confirm new password"
            defaultStyle="primary"
            onChange={(value) => {
              updateForm(formData, setFormData, 'passwordConfirm', value);
            }}
            disabled={loading}
          />
        </InputLabel>
        <span>
          <Button defaultStyle='d-block primary less_padding' type="submit" text="Update" disabled={loading} />
        </span>
      </form>
    </>
  );
};

export default ResetPasswordForm;