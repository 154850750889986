/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "./Flights.style.scss";

import Table from "components/generics/Table/ReactTable";
import Modal from "components/generics/Modal/Modal";
import FlightForm from "components/pages/Flights/FlightForm";
import ConfirmDelete from "components/generics/ConfirmDelete/ConfirmDelete";
import FlightButton from "components/pages/Flights/FlightButton";

import useAuth from "providers/Auth/useAuth";
import { prepareTable, getFlightHeaderByRole, formatMinutesToReadableHour } from "utils/func";

import {
  get as getFlights,
  bulkDelete as deleteFlights,
  bulkCancel as cancelFlights,
  exportData,
} from "services/API/Flight";

const Flights = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormType] = useState(null);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getFlights()
      .then((res) => {
        if (user.roleName !== "CREW") {
          setData({
            datas: prepareTable(
              getFlightHeaderByRole(user.roleName),
              res.data
                .sort((a, b) => a.takeoffTimeUtc - b.takeoffTimeUtc)
                .sort((a, b) => +new Date(a.date) - +new Date(b.date))
            ),
            headers: getFlightHeaderByRole(user.roleName),
          });
        } else {
          setData({
            datas: prepareTable(
              getFlightHeaderByRole(user.roleName),
              res.data
                .filter(
                  (item) => item.aircraft.airline.name === user.airlineName
                )
                .sort((a, b) => a.takeoffTimeUtc - b.takeoffTimeUtc)
                .sort((a, b) => +new Date(a.date) - +new Date(b.date))
            ),
            headers: getFlightHeaderByRole(user.roleName),
          });
        }
      })
      .catch((e) => toast.error(e.message));
  };

  useEffect(() => {
    if (selectedFlights.length === 1) {
      setEditId(selectedFlights[0].original.id);
    } else {
      setEditId(null);
    }
  }, [selectedFlights]);

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  };

  const onDelete = () => {
    if (selectedFlights.length > 0) {
      deleteFlights({ ids: selectedFlights.map((v) => +v.original.id) })
        .then((e) => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const onCancel = () => {
    if (selectedFlights.length > 0) {
      cancelFlights({ ids: selectedFlights.map((v) => +v.original.id) })
        .then((e) => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const onDownload = () => {
    setDownloading(true);
    exportData()
      .then((resp) => {
        let newCsv = ""
        let splitedFlight = resp.data.split("\n")
        newCsv += splitedFlight[0]+"\n"
        splitedFlight.forEach((f, idx) => {
          if(idx > 0) {
            let splitedInfo = f.split(",")
            splitedInfo[2] = formatMinutesToReadableHour(splitedInfo[2])
            splitedInfo[3] = formatMinutesToReadableHour(splitedInfo[3])
            splitedInfo[4] = formatMinutesToReadableHour(splitedInfo[4])
            splitedInfo[5] = formatMinutesToReadableHour(splitedInfo[5])
            splitedInfo[6] = formatMinutesToReadableHour(splitedInfo[6])
            splitedInfo[7] = formatMinutesToReadableHour(splitedInfo[7])
            splitedInfo[29] = formatMinutesToReadableHour(splitedInfo[29])
            splitedInfo[30] = formatMinutesToReadableHour(splitedInfo[30])
            splitedInfo[31] = formatMinutesToReadableHour(splitedInfo[31])
            splitedInfo[32] = formatMinutesToReadableHour(splitedInfo[32])
            splitedInfo[33] = formatMinutesToReadableHour(splitedInfo[33])
            splitedInfo[34] = formatMinutesToReadableHour(splitedInfo[34])
            splitedInfo[43] = formatMinutesToReadableHour(splitedInfo[43])
            splitedInfo[44] = formatMinutesToReadableHour(splitedInfo[44])
            splitedInfo[45] = formatMinutesToReadableHour(splitedInfo[45])
            splitedInfo[46] = formatMinutesToReadableHour(splitedInfo[46])

            newCsv += splitedInfo.join(',')+"\n"
          }
        })
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob([newCsv], { type: resp.headers["content-type"] })
        );
        downloadLink.download = "export";
        downloadLink.click();
        setDownloading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setDownloading(false);
      });
  };

  return (
    <div className="flights" id="flights-all">
      <div className="crud__header">
        <div>
          <h1>All flight</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
        <FlightButton
          allowedRoles={["Admin", "CPSAdmin", "CPSUser", "DISPATCH"]}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType("edit");
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={["ASO"]}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType("editASO");
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={["CREW"]}
          text="Edit"
          disabled={selectedFlights.length !== 1}
          defaultStyle="primary"
          onClick={() => {
            setFormType("editCREW");
            setModalVisibility(true);
          }}
        />
        <FlightButton
          allowedRoles={["Admin", "CPSAdmin", "CPSUser", "DISPATCH"]}
          text="Delete"
          disabled={selectedFlights.length === 0}
          defaultStyle="primary"
          onClick={() => setConfirmDeletePopup(true)}
        />
        <FlightButton
          allowedRoles={["ASO", "CPSAdmin", "CPSUser", "Admin"]}
          text="Export"
          disabled={downloading}
          defaultStyle="primary"
          onClick={onDownload}
        />
        <FlightButton
          allowedRoles={["Admin", "CPSAdmin", "CPSUser", "DISPATCH"]}
          text="Cancel"
          disabled={selectedFlights.length === 0}
          defaultStyle="primary"
          onClick={onCancel}
        />
        <FlightButton
          allowedRoles={["Admin", "CPSAdmin", "DISPATCH"]}
          text="Add"
          defaultStyle="primary"
          onClick={() => {
            setFormType("add");
            setModalVisibility(true);
          }}
        />
      </div>
      <Table
        selectedRows={(d) => setSelectedFlights(d)}
        datas={data.datas}
        headers={data.headers}
        name="allFlightsFilters"
      />
      {modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FlightForm onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      )}
      {confirmDeletePopup && (
        <ConfirmDelete
          onClose={() => setConfirmDeletePopup(false)}
          list={selectedFlights}
          onConfirm={onDelete}
          formatList={(data) => `Item : ${data.name}`}
        />
      )}
    </div>
  );
};

export default Flights;
