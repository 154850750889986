import React from "react";
import PropTypes from 'prop-types';

const ParametersIcon = ({ color, fill, width, height }) => (
  <svg
    width={width}
    height={height}
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          fill={fill}
          color={color}
          d="M0 0h35.719v36H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Groupe 167" clipPath="url(#prefix__a)">
      <path
        d="M35.165 21.89L33.2 20.755a3.181 3.181 0 010-5.511l1.965-1.135a1.054 1.054 0 00.386-1.44l-4.236-7.338a1.055 1.055 0 00-1.441-.386L27.908 6.08a3.18 3.18 0 01-4.771-2.755v-2.27A1.055 1.055 0 0022.082.001H13.61a1.054 1.054 0 00-1.054 1.054v2.27A3.181 3.181 0 017.784 6.08L5.818 4.945a1.055 1.055 0 00-1.441.386L.141 12.669a1.054 1.054 0 00.386 1.44l1.965 1.135a3.181 3.181 0 010 5.511L.527 21.89a1.055 1.055 0 00-.386 1.441l4.236 7.337a1.056 1.056 0 001.441.386l1.966-1.135a3.181 3.181 0 014.772 2.755v2.27a1.054 1.054 0 001.054 1.054h8.472a1.054 1.054 0 001.054-1.054v-2.27a3.181 3.181 0 014.772-2.755l1.966 1.135a1.054 1.054 0 001.44-.386l4.237-7.337a1.055 1.055 0 00-.386-1.441zm-5.149 6.811l-1.053-.608a5.289 5.289 0 00-7.935 4.582v1.216h-6.363v-1.216a5.289 5.289 0 00-7.935-4.582l-1.053.608-3.181-5.511 1.053-.608a5.291 5.291 0 000-9.165l-1.053-.608 3.181-5.511 1.053.608a5.289 5.289 0 007.935-4.582v-1.22h6.363V3.32a5.289 5.289 0 007.935 4.582l1.053-.608 3.181 5.511-1.053.608a5.291 5.291 0 000 9.165l1.053.608zm0 0"
        fill={fill}
        color={color}
      />
    </g>
    <path
      d="M17.846 10.617a7.382 7.382 0 107.382 7.382 7.39 7.39 0 00-7.382-7.382zm0 12.656a5.274 5.274 0 115.273-5.274 5.28 5.28 0 01-5.273 5.274zm0 0"
      fill={fill}
      color={color}
    />
  </svg>
)

ParametersIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ParametersIcon.defaultProps = {
  color: '#000',
  fill: '#fff',
  width: 35.719,
  height: 36,
};

export default ParametersIcon