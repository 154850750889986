import React from 'react';
import { v1 } from 'uuid';

import { QuickFlightsHeaders } from 'utils/const';

const QuickFlightsTableHead = () => (
  <thead>
    <tr>
      { QuickFlightsHeaders.map(item => (
        <th key={v1()} scope='col'>
          <div>
            {React.cloneElement(item.icon, { fill: '#03A9F4' })}
            <span>{item.title}</span>
          </div>
        </th>
        )
      )}
    </tr>
  </thead>
);

export default QuickFlightsTableHead;