/* eslint-disable import/no-anonymous-default-export */
import API from 'services/API';

export const fuel = () => API.get('/stat/fuelData');

export const classDelays = () => API.get('/stat/delayDataByClass');

export const typeDelays = () => API.get('/stat/delayDataByType');

export const usedSeat = () => API.get('/stat/seatAvailableDataByClass');

export const globalSeat = () => API.get('/stat/seatGlobalStudyData');

export const dateMissionSeat = () => API.get('/stat/seatDataByDateAndMission');

export const missions = () => API.get('/stat/missionData');

export const flightTimes = () => API.get('/stat/aircraftFlightTimeData');