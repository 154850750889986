import { isAuthorised } from 'utils/func';

import useAuth from 'providers/Auth/useAuth';

const RoleGuard = ({ allowedRoles, children }) => {
  const { user } = useAuth();
  
  return (
    isAuthorised(allowedRoles, user.roleName) && children
  );
}

export default RoleGuard;