import { useState, useEffect } from 'react';
import { v1 } from 'uuid';

import InputLabel from 'components/generics/InputLabel/InputLabel';
import Tag from 'components/generics/Tag/Tag';
import Input from 'components/generics/Input/Input';
import Button from 'components/generics/Button/Button';
import useAuth from 'providers/Auth/useAuth';

import {
  get as getMissions,
  add as addMission,
  del as deleteMission
} from 'services/API/Mission';

import './Missions.style.scss';

const Mission = () => {
  const [inputValue, setInputValue] = useState('');
  const [missions, setMissions] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, [])

  const getDatas = () => {
    getMissions()
      .then(res => {
        setMissions(res.data);
      })
      .catch(e => console.error(e));
  }

  const onDelete = (id) => {
    deleteMission(id)
      .then(res => getDatas())
      .catch(err => console.log(err))
  }

  const onAdd = (name) => {
    addMission({ name: inputValue })
      .then(res => {
        getDatas();
        setInputValue('');
      })
      .catch(err => console.log(err));
  }

  return (
    <div className='params crud' id="mission">
      <div className="crud__header">
        <div>
          <h1>Missions</h1>
          <h2>{user?.datasource.replace("_", " ")}</h2>
        </div>
      </div>
      <div className="content">
        <InputLabel
          label='CREATE A MISSION'
          name='mission'
        >
          <Input
            name='mission'
            type='text'
            placeholder='Enter a name'
            onChange={(value) => setInputValue(value)}
            value={inputValue}
          />
        </InputLabel>
        <Button
          text={'add'}
          disabled={inputValue === ''}
          defaultStyle={`primary`}
          onClick={(e) => onAdd()}
        />
        <div className='tag__container'>
          {missions.map(v => <Tag key={v1()} title={v.name} onDelete={() => onDelete(v.id)} />)}
        </div>
      </div>
    </div>
  );
}

export default Mission;