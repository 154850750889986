import { ResponsiveBar } from "@nivo/bar";

const Bar = ({ data, keys, indexBy = 'id', valueScale = 'linear', colors = '#03A9F4', label = true, mode = 'stacked', labels = false }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 10, bottom: 30, left: 60 }}
    padding={0.5}
    groupMode={mode}
    valueScale={{ type: valueScale }}
    indexScale={{ type: 'band', round: true }}
    colors={colors}
    borderColor={{ from: 'color', modifiers: [ [ 'brighter', 1.6 ] ] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor="#fff"
    enableLabel={label}
    tooltip={(e) => <p className='overBarGraph'><span className='overLegendColor' style={{ backgroundColor: e.color}}></span>{ labels ? labels[keys.indexOf(e.id)] : e.data[indexBy]} - {e.data[e.id]}</p>}
  />
)

export default Bar;
