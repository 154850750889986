import { useState } from 'react';

import MenuIcon from 'components/icons/MenuIcon';
import ProfileIcon from 'components/icons/ProfileIcon';
import Button from '../Button/Button';

import useAuth from 'providers/Auth/useAuth';
import { formatDateAndHour } from 'utils/func';

import './header.scss';

const Header = ({ connectionState, toggleMenu, navState}) => {
  const [accountInformationsState, setAccountInformationsState] = useState(false)
  const { logout, user } = useAuth();

  return (
    <header>
      <div onClick={toggleMenu}>
        <MenuIcon active={navState} fill='#000' />
      </div>
      <div>
        {/* <span className='connectionState'>{ connectionState.state }</span> */}
        <div onClick={() => setAccountInformationsState(!accountInformationsState)}>
          <ProfileIcon />
        </div>
      </div>
      {( accountInformationsState &&
        <div className='accountInformations'>
          <div className='accountInformations__content'>
            <p>Logged as <span>{user.email}</span></p>
            <p>Firstname: <span>{user.firstname}</span></p>
            <p>Lastname: <span>{user.lastname}</span></p>
            <p><span>{user.roleName}</span></p>
            <Button
              text='Logout'
              defaultStyle='primary less_padding'
              onClick={logout}
            />
            <p>Expire token : <span>{formatDateAndHour(new Date(user.exp * 1000))}</span></p>
            <p>Version : <span>{process.env.REACT_APP_VERSION}</span></p>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header;
