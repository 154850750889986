import { useEffect, useState } from 'react';

import SeatUsed from 'components/pages/Stats/Seat/SeatUsed';
import GlobalSeats from 'components/pages/Stats/Seat/GlobalSeats';
import Table from 'components/generics/Table/ReactTable';

import './SeatStats.style.scss';

import { usedSeat, globalSeat, dateMissionSeat } from 'services/API/Stats';
import { prepareTable } from 'utils/func';
import { SEATS_STATS_HEADERS } from 'utils/const';

const SeatsStats = () => {
  const [usedSeatData, setUsedSeatData] = useState(null)
  const [loadingUsedSeat, setLoadingUsedSeat] = useState(true)
  const [globalSeatData, setGlobalSeatData] = useState(null)
  const [loadingGlobalSeat, setLoadingGlobalSeat] = useState(true)
  const [seatStageData, setSeatStageData] = useState(null)
  const [loadingSeatStage, setLoadingSeatStage] = useState(true)

  useEffect(() => {
    usedSeat()
      .then(res => {
        setUsedSeatData(res.data.seatsAvailableClasses.filter(v => v.class !== ''));
        setLoadingUsedSeat(false);
      })
      .catch(err => console.error(err))
    globalSeat()
      .then(res => {
        setGlobalSeatData([
          {
            id: "Seats unused",
            value: res.data.totalSeats - res.data.totalSeatsUsed,
          },
          {
            id: "Seats used",
            value: res.data.totalSeatsUsed,
          },
          {
            id: "Total seats",
            value: res.data.totalSeats,
          },
          {
            id: "Seats unused/flight",
            value: ((res.data.totalSeats - res.data.totalSeatsUsed) / res.data.flightsCounter).toFixed(2),
          },
          {
            id: "Seats unused/day",
            value: ((res.data.totalSeats - res.data.totalSeatsUsed) / res.data.datesCounter).toFixed(2),
          }
        ]);
        setLoadingGlobalSeat(false);
      })
      .catch(err => console.error(err))
    dateMissionSeat()
      .then(res => {
        setSeatStageData({ datas: prepareTable(SEATS_STATS_HEADERS, res.data.seatsBydateAndMission), headers: SEATS_STATS_HEADERS })
        setLoadingSeatStage(false);
      })
      .catch(err => console.error(err))
  }, [])

  return (
    <>
      <div id="SeatsStats" className="statsContainer">
        <div className="statsSection" id="SeatsUsed">
          <h3>Seats used</h3>
          <SeatUsed data={usedSeatData} loading={loadingUsedSeat} />
        </div>
        <div className="statsSection" id="SeatsGlobal">
          <h3>Global seats study</h3>
          <GlobalSeats data={globalSeatData} loading={loadingGlobalSeat} />
        </div>
      </div>
      <div className='statsSection' id="SeatsStages">
        { loadingSeatStage ? (
          <div>Loading</div>
        ) : (
          <>
            <h3>Seats by stages</h3>
            <Table
              selectedRows={() => {}}
              datas={seatStageData.datas}
              headers={seatStageData.headers}
              filters={false}
              selectable={false}
              name='seatsFilters'
            />
          </>
        )}
      </div>
    </>
  );
};

export default SeatsStats;